import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import EtherscanProgress from "../../common/EtherscanProgress";
import CustomModal from "../../common/CustomModal";
import Forge from "../../Forge/Forge";
import { getContractSync } from "../../Utils/GetContract";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import useWeb3Ctx from "../../../hooks/useWeb3Ctx";
import config from "../../../config";
import bgImage from "../../../assets/images/new-bg-2.jpg";

import { toast } from "react-toast";

const ForgeModal = ({ show, setShow }) => {
  const { address, ethersProvider, chainId } = useWeb3Ctx();
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);

  const saleForgeContract = useMemo(() => {
    const contract = getContractSync(
      "LameloSaleForge",
      ethersProvider,
      chainId
    );
    if (address) {
      let signer = ethersProvider.getSigner();
      return contract.connect(signer);
    } else return contract;
  }, [ethersProvider, address]);

  const onClickForge = async (goldId, silverId, blueId, redId) => {
    setApproveInProgress(true);
    await saleForgeContract
      .forge([goldId, silverId, blueId, redId])
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_TX}${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_TX}${tx.hash}`);
        return tx.wait().then((receipt) => {
          // console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Forged NFT");
            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
              state: {
                dropId: 1,
              },
            });
            setTxEtherScan(null);
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);
    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const handleError = (e) => {
    console.error(e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };
  return (
    <CustomModal
      fullscreen={true}
      fullWidth={true}
      showModal={show}
      setShowModal={setShow}
      style={{
        background: `url(${bgImage})`,
        paddingTop: width < 576 ? "20px" : "80px",
      }}
      overlayStyle={{ background: "transparent" }}
      approveInProgress={approveInProgress}
      txInProgress={txInProgress}
    >
      {!txInProgress ? (
        <>
          <h1 className="text-center">FORGE</h1>

          <p className="text-center">
            Owners of a full collection from Drop 001 (Gold, Silver, Blue, Red)
            can use the Forge to mint a Drop 002 NFT.
            <br></br>Please note that Drop 001 tokens are eligible for going
            through Forge only once.
          </p>

          {show && <Forge onClickForge={onClickForge} />}
        </>
      ) : (
        <EtherscanProgress txEtherScan={txEtherScan} />
      )}
    </CustomModal>
  );
};

export default ForgeModal;
