import { ethers } from 'ethers';
import AddressesMain from '../../contracts/addresses-1.json';
import AddressesRinkeby from '../../contracts/addresses-4.json';
import AddressesLocal from '../../contracts/addresses-1337.json';

import LaMelo from '../../contracts/LaMelo.json';
import LaMeloAuction from '../../contracts/ECAuction.json';
import Zoom2 from '../../contracts/Zoom2.json';
import LaMeloRaffle from '../../contracts/LameloRaffle.json';

import LameloSaleForge from '../../contracts/LameloSaleForge.json';
import EtherCards from "../../contracts/EtherCards.json"

const contractAbis = { LaMelo, LaMeloAuction, Zoom2, LaMeloRaffle, LameloSaleForge, EtherCards };
const addresses = {
    1: { addr: AddressesMain, name: 'Main' },
    4: { addr: AddressesRinkeby, name: 'Rinkeby' },
    1337: { addr: AddressesLocal, name: 'localhost' }
};

let theContract = {};

export const getContract = async (contractName, provider) => {
    //console.log('get contract fn',provider);
    if (theContract[contractName]) {
        console.log('cached contract found', theContract[contractName]);
        return theContract[contractName];
    }

    let ntw = await provider.getNetwork();
    //console.log('nnetwork',ntw);

    let contractAbi = contractAbis[contractName];
    let address = addresses[ntw.chainId].addr[contractName];

    let contract = null;

    try {
        contract = new ethers.Contract(address, contractAbi.abi, provider);
    } catch {
        console.log('contract not found');
    }
    //console.log(contractName,contract);
    theContract[contractName] = contract;
    return contract;
}


export const getContractSync = (contractName, provider, chainId) => {
    let contractAbi = contractAbis[contractName];
    let address = addresses[chainId].addr[contractName];

    let contract = null;

    try {
        contract = new ethers.Contract(address, contractAbi.abi, provider);
    } catch {
        console.log("contract not found");
    }
    //console.log(contractName,contract);
    return contract;
};