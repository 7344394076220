import React, { useEffect, useState } from 'react';
import { padZeros } from '../Utils';
import useInterval from './useInterval';
import './Countdown.css';

const Countdown = (props) => {
    const [hoursMinutesOnly,setHoursMinutesOnly] = useState(false);
    const [displayShortNames,setDisplayShortNames] = useState(false);
    const [smallFont,setSmallFont] = useState(false);
  

    const [startDate,setStartDate] = useState(0);
    const [timeToGo,setTimeToGo] = useState(
        {
            days: 0,
            hours:0,
            minutes:0,
            seconds:0
         });

    useEffect(()=>{
        if(props.startDate && props.startDate!=startDate){
            
            setStartDate(props.startDate);
        }

        if(props.hoursMinutesOnly){
            setHoursMinutesOnly(true);
        }

        if(props.short){
            setDisplayShortNames(true);
        }

        if(props.small){
            setSmallFont(true);
        }

    },[props]);

    useInterval(()=>{
        tick();
    },1000);

    const tick = () =>{
        let tLeft={
            days: 0,
            hours:0,
            minutes:0,
            seconds:0
        }

        if(startDate > 0){

            let now = Date.parse(new Date())/1000;
            let diff = Math.floor(startDate/1000) - now;
            //console.log('now',startDate/1000,now,diff);

            if(diff>0){
                if(diff>86400){
                    tLeft.days = Math.floor(diff/86400);
                    diff -= tLeft.days*86400;
                }
                if(diff>3600){
                    tLeft.hours = Math.floor(diff/3600);
                    diff -= tLeft.hours*3600;
                }
                if(diff>60){
                    tLeft.minutes = Math.floor(diff/60);
                    diff -= tLeft.minutes*60;
                }
                tLeft.seconds=diff;
            }else{
                if(props.onFinish){
                    props.onFinish();
                }
            }
        }
        setTimeToGo(tLeft);
    }


    return(
    <>
        <div className={`${props.className} counter-container d-flex justify-content-between ${smallFont?'small-values':''}`}>
            <div className="item mx-auto">
                {!hoursMinutesOnly&&
                <>
                    <span className="val">{padZeros(timeToGo.days,2)}</span>
                    <span className={`label ${displayShortNames?'smaller':''}`}>
                        {displayShortNames ? 
                        <>D</>
                        :
                        <>DAY{timeToGo.days===1?'':'S'}</>
                        }
                    </span>
                </>
                }
     
                <span className="val">{padZeros(timeToGo.hours,2)}</span>
                <span className={`label ${displayShortNames?'smaller':''}`}>
                    {displayShortNames ? 
                        <>H</>
                        :
                        <>HOUR{timeToGo.hours===1?'':'S'}</>
                    }
                </span>
       
                <span className="val">{padZeros(timeToGo.minutes,2)}</span>
                <span className={`label ${displayShortNames?'smaller':''}`}>
                    {displayShortNames ? 
                        <>M</>
                        :
                        <>MINUTE{timeToGo.minutes===1?'':'S'}</>
                    }
                </span>
                {!hoursMinutesOnly&&
                <>
                    <span className="val">{padZeros(timeToGo.seconds,2)}</span>
                    <span className={`label ${displayShortNames?'smaller':''}`}>
                        {displayShortNames ? 
                            <>S</>
                            :
                            <>SECOND{timeToGo.seconds===1?'':'S'}</>
                        }
                    </span>
                </>
                }
            </div>

        </div>
    </>
    );
}

export default Countdown
