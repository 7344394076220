 const points=[
    {date:'10/20/2021',opponent:'IND',fg:'11',pt3:'7',pt2:'4',ft:'2',pts:'31'},
    {date:'10/22/2021',opponent:'CLE',fg:'8',pt3:'1',pt2:'7',ft:'0',pts:'17'},
    {date:'10/24/2021',opponent:'BKN',fg:'8',pt3:'1',pt2:'7',ft:'1',pts:'18'},
    {date:'10/25/2021',opponent:'BOS',fg:'7',pt3:'7',pt2:'0',ft:'4',pts:'25'},
    {date:'10/27/2021',opponent:'ORL',fg:'3',pt3:'1',pt2:'2',ft:'0',pts:'7'},
    {date:'10/29/2021',opponent:'MIA',fg:'2',pt3:'0',pt2:'2',ft:'2',pts:'6'},
    {date:'10/31/2021',opponent:'POR',fg:'10',pt3:'4',pt2:'6',ft:'3',pts:'27'},
    {date:'11/1/2021',opponent:'CLE',fg:'12',pt3:'5',pt2:'7',ft:'1',pts:'30'},
    {date:'11/3/2021',opponent:'GSW',fg:'5',pt3:'0',pt2:'5',ft:'4',pts:'14'},
    {date:'11/5/2021',opponent:'SAC',fg:'9',pt3:'4',pt2:'5',ft:'2',pts:'24'},
    {date:'11/7/2021',opponent:'LAC',fg:'8',pt3:'2',pt2:'6',ft:'3',pts:'21'},
    {date:'11/8/2021',opponent:'LAL',fg:'8',pt3:'3',pt2:'5',ft:'6',pts:'25'},
    {date:'11/10/2021',opponent:'MEM',fg:'5',pt3:'2',pt2:'3',ft:'0',pts:'12'},
    {date:'11/12/2021',opponent:'NYK',fg:'4',pt3:'0',pt2:'4',ft:'4',pts:'12'},
    {date:'11/14/2021',opponent:'GSW',fg:'8',pt3:'4',pt2:'4',ft:'1',pts:'21'},
    {date:'11/17/2021',opponent:'WAS',fg:'4',pt3:'2',pt2:'2',ft:'1',pts:'11'},
    {date:'11/19/2021',opponent:'IND',fg:'12',pt3:'4',pt2:'8',ft:'4',pts:'32'},
    {date:'11/20/2021',opponent:'ATL',fg:'5',pt3:'3',pt2:'2',ft:'4',pts:'15'},
    {date:'11/22/2021',opponent:'WAS',fg:'10',pt3:'3',pt2:'7',ft:'5',pts:'28'},
    {date:'11/24/2021',opponent:'ORL',fg:'7',pt3:'4',pt2:'3',ft:'4',pts:'22'},
    {date:'11/26/2021',opponent:'MIN',fg:'4',pt3:'0',pt2:'4',ft:'2',pts:'10'},
    {date:'11/27/2021',opponent:'HOU',fg:'7',pt3:'2',pt2:'5',ft:'3',pts:'19'},
    {date:'11/29/2021',opponent:'CHI',fg:'5',pt3:'3',pt2:'2',ft:'5',pts:'18'},
    {date:'12/1/2021',opponent:'MIL',fg:'12',pt3:'8',pt2:'4',ft:'4',pts:'36'},
    {date:'12/5/2021',opponent:'ATL',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/6/2021',opponent:'PHI',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/8/2021',opponent:'PHI',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/10/2021',opponent:'SAC',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/13/2021',opponent:'DAL',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/15/2021',opponent:'SAS',fg:'0',pt3:'0',pt2:'0',ft:'0',pts:'0'},
    {date:'12/17/2021',opponent:'POR',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'12/19/2021',opponent:'PHX',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'12/20/2021',opponent:'UTA',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'12/23/2021',opponent:'DEN',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'12/27/2021',opponent:'HOU',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'12/29/2021',opponent:'IND',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/2/2022',opponent:'PHX',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/3/2022',opponent:'WAS',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/5/2022',opponent:'DET',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/8/2022',opponent:'MIL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/10/2022',opponent:'MIL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/12/2022',opponent:'PHI',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/14/2022',opponent:'ORL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/17/2022',opponent:'NYK',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/19/2022',opponent:'BOS',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/21/2022',opponent:'OKC',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/23/2022',opponent:'ATL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/25/2022',opponent:'TOR',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/26/2022',opponent:'IND',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/28/2022',opponent:'LAL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'1/30/2022',opponent:'LAC',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/2/2022',opponent:'BOS',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/4/2022',opponent:'CLE',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/5/2022',opponent:'MIA',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/7/2022',opponent:'TOR',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/9/2022',opponent:'CHI',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/11/2022',opponent:'DET',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/12/2022',opponent:'MEM',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/15/2022',opponent:'MIN',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/17/2022',opponent:'MIA',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/25/2022',opponent:'TOR',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/27/2022',opponent:'DET',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'2/28/2022',opponent:'MIL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/2/2022',opponent:'CLE',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/5/2022',opponent:'SAS',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/8/2022',opponent:'BKN',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/9/2022',opponent:'BOS',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/11/2022',opponent:'NOP',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/14/2022',opponent:'OKC',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/16/2022',opponent:'ATL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/19/2022',opponent:'DAL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/21/2022',opponent:'NOP',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/23/2022',opponent:'NYK',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/25/2022',opponent:'UTA',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/27/2022',opponent:'BKN',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/28/2022',opponent:'DEN',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'3/30/2022',opponent:'NYK',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'4/2/2022',opponent:'PHI',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'4/5/2022',opponent:'MIA',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'4/7/2022',opponent:'ORL',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'4/8/2022',opponent:'CHI',fg:'',pt3:'',pt2:'',ft:'',pts:''},
    {date:'4/10/2022',opponent:'WAS',fg:'',pt3:'',pt2:'',ft:'',pts:''}
];


export default points;