 const points=[
    {date:'10/20/2021',opponent:'IND',steals:'2'},
    {date:'10/22/2021',opponent:'CLE',steals:'5'},
    {date:'10/24/2021',opponent:'BKN',steals:'0'},
    {date:'10/25/2021',opponent:'BOS',steals:'0'},
    {date:'10/27/2021',opponent:'ORL',steals:'3'},
    {date:'10/29/2021',opponent:'MIA',steals:'0'},
    {date:'10/31/2021',opponent:'POR',steals:'1'},
    {date:'11/1/2021',opponent:'CLE',steals:'2'},
    {date:'11/3/2021',opponent:'GSW',steals:'4'},
    {date:'11/5/2021',opponent:'SAC',steals:'0'},
    {date:'11/7/2021',opponent:'LAC',steals:'3'},
    {date:'11/8/2021',opponent:'LAL',steals:'1'},
    {date:'11/10/2021',opponent:'MEM',steals:'2'},
    {date:'11/12/2021',opponent:'NYK',steals:'5'},
    {date:'11/14/2021',opponent:'GSW',steals:'3'},
    {date:'11/17/2021',opponent:'WAS',steals:'2'},
    {date:'11/19/2021',opponent:'IND',steals:'1'},
    {date:'11/20/2021',opponent:'ATL',steals:'2'},
    {date:'11/22/2021',opponent:'WAS',steals:'3'},
    {date:'11/24/2021',opponent:'ORL',steals:'0'},
    {date:'11/26/2021',opponent:'MIN',steals:'1'},
    {date:'11/27/2021',opponent:'HOU',steals:'1'},
    {date:'11/29/2021',opponent:'CHI',steals:'1'},
    {date:'12/1/2021',opponent:'MIL',steals:'3'},
    {date:'12/5/2021',opponent:'ATL',steals:'0'},
    {date:'12/6/2021',opponent:'PHI',steals:'0'},
    {date:'12/8/2021',opponent:'PHI',steals:'0'},
    {date:'12/10/2021',opponent:'SAC',steals:'0'},
    {date:'12/13/2021',opponent:'DAL',steals:'0'},
    {date:'12/15/2021',opponent:'SAS',steals:'0'},
    {date:'12/17/2021',opponent:'POR',steals:''},
    {date:'12/19/2021',opponent:'PHX',steals:''},
    {date:'12/20/2021',opponent:'UTA',steals:''},
    {date:'12/23/2021',opponent:'DEN',steals:''},
    {date:'12/27/2021',opponent:'HOU',steals:''},
    {date:'12/29/2021',opponent:'IND',steals:''},
    {date:'1/2/2022',opponent:'PHX',steals:''},
    {date:'1/3/2022',opponent:'WAS',steals:''},
    {date:'1/5/2022',opponent:'DET',steals:''},
    {date:'1/8/2022',opponent:'MIL',steals:''},
    {date:'1/10/2022',opponent:'MIL',steals:''},
    {date:'1/12/2022',opponent:'PHI',steals:''},
    {date:'1/14/2022',opponent:'ORL',steals:''},
    {date:'1/17/2022',opponent:'NYK',steals:''},
    {date:'1/19/2022',opponent:'BOS',steals:''},
    {date:'1/21/2022',opponent:'OKC',steals:''},
    {date:'1/23/2022',opponent:'ATL',steals:''},
    {date:'1/25/2022',opponent:'TOR',steals:''},
    {date:'1/26/2022',opponent:'IND',steals:''},
    {date:'1/28/2022',opponent:'LAL',steals:''},
    {date:'1/30/2022',opponent:'LAC',steals:''},
    {date:'2/2/2022',opponent:'BOS',steals:''},
    {date:'2/4/2022',opponent:'CLE',steals:''},
    {date:'2/5/2022',opponent:'MIA',steals:''},
    {date:'2/7/2022',opponent:'TOR',steals:''},
    {date:'2/9/2022',opponent:'CHI',steals:''},
    {date:'2/11/2022',opponent:'DET',steals:''},
    {date:'2/12/2022',opponent:'MEM',steals:''},
    {date:'2/15/2022',opponent:'MIN',steals:''},
    {date:'2/17/2022',opponent:'MIA',steals:''},
    {date:'2/25/2022',opponent:'TOR',steals:''},
    {date:'2/27/2022',opponent:'DET',steals:''},
    {date:'2/28/2022',opponent:'MIL',steals:''},
    {date:'3/2/2022',opponent:'CLE',steals:''},
    {date:'3/5/2022',opponent:'SAS',steals:''},
    {date:'3/8/2022',opponent:'BKN',steals:''},
    {date:'3/9/2022',opponent:'BOS',steals:''},
    {date:'3/11/2022',opponent:'NOP',steals:''},
    {date:'3/14/2022',opponent:'OKC',steals:''},
    {date:'3/16/2022',opponent:'ATL',steals:''},
    {date:'3/19/2022',opponent:'DAL',steals:''},
    {date:'3/21/2022',opponent:'NOP',steals:''},
    {date:'3/23/2022',opponent:'NYK',steals:''},
    {date:'3/25/2022',opponent:'UTA',steals:''},
    {date:'3/27/2022',opponent:'BKN',steals:''},
    {date:'3/28/2022',opponent:'DEN',steals:''},
    {date:'3/30/2022',opponent:'NYK',steals:''},
    {date:'4/2/2022',opponent:'PHI',steals:''},
    {date:'4/5/2022',opponent:'MIA',steals:''},
    {date:'4/7/2022',opponent:'ORL',steals:''},
    {date:'4/8/2022',opponent:'CHI',steals:''},
    {date:'4/10/2022',opponent:'WAS',steals:''}
];


export default points;