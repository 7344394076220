import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Web3Ctx from '../Context/Web3Ctx';
import './Card2.css';
import useContainerDimensions from '../common/useContainerDimensions';
import EmbeddedCard2 from '../common/EmbeddedCard2';
import { getContractSync } from "../Utils/GetContract";
import { getTokenMeta } from '../Utils';

const Card = (props) => {

    const { ethersProvider, chainId } = useContext(Web3Ctx);

    const containerRef = useRef()
    const { width, height } = useContainerDimensions(containerRef);
    const [metadata, setMetadata] = useState(null);

    useEffect(() => {

    }, [width, height]);

    const saleForgeContract = useMemo(() => {
        const contract = getContractSync(
            "LameloSaleForge",
            ethersProvider,
            chainId
        );
        return contract;
    }, [ethersProvider]);

    useEffect(() => {
        let id = props.match.params.tokenId ? parseInt(props.match.params.tokenId) : 0;

        if (id > 3500) {
            // setIllegalId(true);
            return;
        } else {
            //  setIllegalId(false);
            // Contract will add offset to metadata tokenIds
            // const shiftedId = Number(tokenId);
            // const unshiftedId = Number(unshiftTokenId(shiftedId, offset))
            // console.log(`shiftedId: ${shiftedId} - offset: ${offset} = tokenId: ${unshiftedId}`)
            // setTokenId(id);

            getMetadata(id)
        }

    }, [props, saleForgeContract]);

    const getMetadata = async (id) => {
        if (saleForgeContract) {
            const tokenUri = await saleForgeContract.tokenURI(id);
            const metadata = await getTokenMeta(tokenUri)
            setMetadata(metadata);
        }
    }
    return (
        <div ref={containerRef} className="e-container OS">
            <div className="positioner mx-auto p-4" style={{
                width: width < height / 1.365 ? 100 + '%' : height / 1.365,
                marginTop: width < height / 1.365 ? (height - (width * 1.365)) / 2 : 0
            }}>
                {metadata != null && <EmbeddedCard2
                    metadata={metadata}
                />}
            </div>

        </div>
    );
}

export default Card;