const dev = {
    DEPLOYED_NTW_NAME: 'rinkeby',
    DEPLOYED_CHAIN_ID: 4,
    INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
    FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
    RPC_URL: 'https://rinkeby.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
    // RPC_URL: 'https://small-lingering-feather.rinkeby.quiknode.pro/4093cd8bffd26688802327779af8b485f8759f3e/',

    AGGREGATOR_URL: 'https://sheltered-ravine-41936.herokuapp.com/api/addresses/',
    // META_SERVER: 'https://lamelo-sale.staging.burneth.com/staging',
    META_SERVER: 'https://client-metadata-staging.herokuapp.com/api/lamelo',
    //META_SERVER: 'http://localhost:5000/api/lamelo',
    META_SERVER2: 'https://client-metadata-staging.herokuapp.com/api/lamelo2',

    ETHERSCAN_TX: 'https://rinkeby.etherscan.io/tx/'
};

const prod = {
    DEPLOYED_NTW_NAME: 'mainnet',
    DEPLOYED_CHAIN_ID: 1,
    INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
    FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
    RPC_URL: 'https://mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
    //RPC_URL:'https://icy-weathered-glade.quiknode.pro/74e3e2900f41260b429e8ac065b8bee720298c94/',
    //AGGREGATOR_URL: 'https://aggregator.ether.cards/api/addresses/',
    AGGREGATOR_URL: 'https://sorry.af/api/addresses/',
    META_SERVER: 'https://client-metadata.ether.cards/api/lamelo',
    META_SERVER2: 'https://client-metadata.ether.cards/api/lamelo2',

    ETHERSCAN_TX: 'https://etherscan.io/tx/'
};

const common = {
    DAPP_ID: 'b15a625a-c5b1-4b1c-a31b-e4072d006153',


    GOOGLEAPP_URL: 'https://script.google.com/macros/s/AKfycbzm8A_jEa1WLUdBjOBTbMnDBARwxYkFXVN63te-XfPKjc6mcmgD7-0Y6OkTmMnE4MWH/exec',
    //GOOGLEAPP_URL: 'https://script.google.com/macros/s/AKfycbyTXBammRZ7rJtCkjLnC4yBgkCIQDfuE_gQBMfpvjyezwUYbZSwViNhySPLyHxEA_9J/exec',

    LAYERS_BASE_URL: 'https://ether-cards.mypinata.cloud/ipfs/Qmcm7BjsmhwWVA611EZSGkxcqt3JmsbF9m37kPNhDLoy4o',
    NFT_ANIMS: [
        'https://ether-cards.mypinata.cloud/ipfs/QmNUzNCxxGd9dvgZSrfd3k9xz2FWNekauVGLqL1DkRN9R7/Sun.mp4',
        /* 'https://ether-cards.mypinata.cloud/ipfs/QmUCzkGbsptts3GBAotGkY99B5kKHRHsvRbzZFhGYvutFe/Saturn.mp4', */
        'https://ether-cards.mypinata.cloud/ipfs/QmeMCM2wtFbUvoSSWLSnCix9ma5HjLxADPcDwRpAHTYRL8/Moon.mp4',
        'https://ether-cards.mypinata.cloud/ipfs/QmVkGQfa2TSyiGPejcJeQeByAcBuV8FKado1aJNauVNYd1/Neptune.mp4',
        'https://ether-cards.mypinata.cloud/ipfs/Qmdf46hp3qiyn9XskzJgeB5WsSMqWNVa2eM96otK5neoGq/Mars.mp4'],
    NFT_IMGS: [
        'https://lameloball.io/metadata/images/Gold-Sun.jpg',
        'https://lameloball.io/metadata/images/Silver-Moon.jpg',
        'https://lameloball.io/metadata/images/Blue-Neptune.jpg',
        'https://lameloball.io/metadata/images/Red-Mars.jpg'
    ],
    NFT_PREREVEAL: [
        'https://ether-cards.mypinata.cloud/ipfs/QmTQCZRS3KtErE7nQQG3QXZR3j9QSwi9SuThjy49dUMSX4'
    ],
    NFT_ANIMS_2: [
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/AllstarBlue.mp4",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/RisingStarOrange.mp4",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/PinkBlock.mp4",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/GreenRebound.mp4",
    ],
    NFT_IMGS_2: [
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/AllstarBlue.jpg",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/RisingStarOrange.jpg",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/PinkBlock.jpg",
        "https://ether-cards.mypinata.cloud/ipfs/QmVUvpBp4DnZUB3vdoRBZcbEK35cGHEfvhq1ZA2Zmam5PS/GreenRebound.jpg"
    ]
}


// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = prod;// process.env.NODE_ENV === "development" ? dev : prod
let config = { ...envConfig, ...common }

export default config;


// pointless comment for test commit
