import React, { useContext, useEffect, useState } from 'react';

import './FaqPage.css';
import faq from './faq.json';
import bgImage from '../../assets/images/drop_bg.jpg';
import icon from '../../assets/images/icon_collapse-expand.png';
import Navigation from '../Navigation/Navigation';
import ReactMarkdown from 'react-markdown';


export const FaqItem = (props) => {
    const gfm = require('remark-gfm');
    
    const [collapsed,setCollapsed] = useState(true);

    return (
        <div key={props.id} className="faq-item mb-3 purple-box"  onClick={()=>setCollapsed(!collapsed)}>
            <button className={`${collapsed?'collapsed':''} item-head d-flex justify-content-between`}>
                <h3>{props.q}</h3>
                <span style={{backgroundImage:`url(${icon})`}}></span>
            </button>

            <div className={`item-content ${collapsed?'coll':''}`}>
              <ReactMarkdown remarkPlugins={[gfm]} children={props.a} />
            </div>
        </div>
    );
}


const FaqPage = (props)=> {






    return (
        <div id="application" style={{backgroundImage:`url(${bgImage})`, backgroundColor:'#1C2330'}}>
            <Navigation {...props}/>
            <div className="container mt-5 h-100">
                <div className="row">
                    <div className="col-md-7 mx-auto pb-5 mb-5">

                        {faq.map((item,i)=>{return(
                            <div key={'t'+i}>
                            <h2  className="text-left mb-4 mt-5">{item.topic}</h2>
                            {item.faq.map((f,j)=>{return(
                       
                                <FaqItem id={'f'+j} key={'fk'+j} q={f.q} a={f.a}/>


                              )})}

                            </div>

                        )})}
                        






                    </div>
                </div>
            </div>
        </div>
                    
    );
}

export default FaqPage;