import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './FooterComponent.css';
import discordLogo from '../../assets/images/discord.png';
import twitterLogo from '../../assets/images/twitter.png';
import config from "../../config"
import RippleButton from '../common/RippleButton';

import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';
import axios from 'axios';

const FooterComponent = (props) => {

  const [emailAddress, setEmailAddress] = useState();
  const [isLoading, setIsLoading] = useState(false)

  const handleSubscribeEmail = async () => {

    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(emailAddress)) {
      toast.error('Enter a valid email address.')
      return;
    }

    if (isLoading) {
      return;
    }


    setIsLoading(true);

    let res = await axios.get(config.GOOGLEAPP_URL,
      {
        params:
        {
          id: 'subscription-form',
          email: emailAddress
        }
      })
      .catch(e => console.log('ERROR:', e))

    setIsLoading(false);

    console.log(res);
    if (res && res.data.result === "success") {
      toast.success("Successfully registered email")
      setEmailAddress('');
    } else {
      toast.error((res && res.data && res.data.error) ? res.data.error : 'Unknown error :/');
    }

  }

  return (
    <div id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 mb-5 mb-sm-0">
            <div id="subscription">
              <h5 className="mb-1 mt-3" style={{ fontSize: "0.8rem" }}>GET ON THE LIST</h5>
              <div className="email-input d-flex">
                <input type="text"
                  className="email-form"
                  placeholder="Email Address"
                  value={emailAddress}
                  onChange={(e) => { setEmailAddress(e.target.value) }}
                />
                <RippleButton className="px-3 email-form-btn"
                  onClick={handleSubscribeEmail}>
                  {isLoading ?
                    <SpinnerDotted size={20} thickness={160} speed={200} color="#fff" />
                    :
                    <>{`>`}</>
                  }
                </RippleButton>
              </div>

            </div>
          </div>
          <div className="col-12 col-sm-6 text-center text-sm-right">
            <div id="contactUs">
              <a href="https://discord.gg/lameloball" target="_blank">
                <img src={discordLogo} alt="discord" />
              </a>
              <a href="https://twitter.com/lameloverse" target="_blank">
                <img src={twitterLogo} alt="twitter" />
              </a>
              <div className="mt-1">
                <p className="copy-right">2022 Playground</p>
              </div>
              <div>
                <Link to="/tnc"
                >
                  Terms & Conditions
                </Link>
              </div>
              <Link to="/privacy-policy">
              Privacy Policy

              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterComponent;