import React, { useEffect, useRef, useState, useContext, memo } from 'react';
import Web3Ctx from '../Context/Web3Ctx';
import useContainerDimensions from './useContainerDimensions';
import './EmbeddedCard2.css';
import config from '../../config';
import { useHistory } from 'react-router';
import { getTokenMeta } from '../Utils';
import useOnScreen from '../../hooks/useOnScreen';

const cardData = [
   { name: '2022 All Star Debut', statLabel: 'stats', class: 'allstar-blue' },
   { name: 'Rising Star', statLabel: 'assists', class: 'rising-star-orange' },
   { name: 'Blocks', statLabel: 'steals', class: 'block-pink' },
   { name: 'Rebounds', statLabel: 'points', class: 'rebound-green' }
]

const seriesIndex = {
   "2022 All Star Debut": 0,
   "2022 Rising Star": 1,
   "Blocks": 2,
   "Rebounds": 3
}

const EmbeddedCard2 = (props) => {

   const history = useHistory();

   const containerRef = useRef()
   const isVisible = useOnScreen(containerRef);
   const { width, height } = useContainerDimensions(containerRef, isVisible);

   const [containerSize, setContainerSize] = useState('medium');
   const [statsVisible, setStatsVisible] = useState(false);
   const [traitsVisible, setTraitsVisible] = useState(false);

   const [tokenId, setTokenId] = useState(null);
   const [cardType, setCardType] = useState(null);
   const [meta, setMeta] = useState(null);
   const [traits, setTraits] = useState([]);
   const [selectedTraitIdx, setSelectedTraitIdx] = useState(null);
   const [selectedTraitId, setSelectedTraitId] = useState(null);
   const [flipped, setFlipped] = useState(false);

   useEffect(() => {
      // setupStats()
   }, [])

   useEffect(() => {
      if (width) {
         let cSize = '';
         if (width > 500) {
            cSize = 'c-xlarge';
         } else if (width > 400) {
            cSize = 'c-large';
         } else if (width > 300) {
            cSize = 'c-medium';
         } else if (width > 240) {
            cSize = 'c-small';
         } else {
            cSize = 'c-xsmall'
         }
         setContainerSize(cSize);
         // console.log(containerSize)
      }
   }, [width])

   useEffect(() => {
      const setupToken = async () => {

         const url = config.META_SERVER2 + '/' + props.tokenId % 100 + '/' + props.tokenId + '.json'

         const m = await getTokenMeta(url);

         setMeta(m);
         setTokenId(m.id);
         setCardType(seriesIndex[m.series]);
      }

      const setupMetadata = (m) => {
         setMeta(m);
         setTokenId(m.id);
         setCardType(seriesIndex[m.series]);
      }

      if (props.metadata) {
         setupMetadata(props.metadata)
      } else if (props.tokenId) {
         setupToken();
      }

   }, [props.tokenId, props.metadata])

   const closeStatsOrTraits = () => {
      if (flipped) {
         setFlipped(false);
         return;
      }

      if (statsVisible || traitsVisible) {
         setStatsVisible(false);
         setTraitsVisible(false);
      }
   }

   const showTrait = (idx, id) => {
      setSelectedTraitIdx(idx);
      setSelectedTraitId(id);
      setFlipped(true);
   }

   return (
      <>
         <div ref={containerRef} className={`nft-token embed  ${!isVisible && "hidden"} ${tokenId === null ? 'hidden' : ''} ${containerSize} ${cardType != null ? cardData[cardType].class : "preview-white"}`}>
            <div className="nft-header d-flex align-items-center">
               <h5 className="mb-0 w-50 pl-2">{cardType != null ? cardData[cardType].name : "??????"}</h5>

               <h5 className={`mb-0 w-25 text-center stats-cta ${props.disableStats && "d-none"}`}></h5>

            </div>

            {meta &&
               <>
                  <div className={`video-container ${props.disableVideo && "d-none"} ${!isVisible && "d-none"} `} >
                     <video
                        loop
                        muted
                        autoPlay
                        playsInline
                        src={`${meta.original_art_url || " https://ether-cards.mypinata.cloud/ipfs/QmTQCZRS3KtErE7nQQG3QXZR3j9QSwi9SuThjy49dUMSX4"}`}
                     />
                  </div>

                  <div className={`img-container ${!props.disableVideo && "d-none"} ${!isVisible && "hide"} `}>
                     <img src={`${meta.image}`}></img>
                  </div>
               </>
            }

            <div className={`stat-footer align-items-center justify-content-end d-flex`} >
               <div className="token-id">{tokenId}</div>
            </div>
         </div>
      </>
   );

}

export default EmbeddedCard2;