import { useState, useEffect } from "react"

export default function useOnScreen(ref, delay) {
    let observer;
    const [isIntersecting, setIntersecting] = useState(false)

    if (IntersectionObserver) {
        observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {
                rootMargin: "0px 0px 200px 0px"
            }
        )
    } else {
        setIntersecting(true)
    }

    useEffect(() => {
        if (observer) {
            if (delay) {
                setTimeout(function () {
                    observer.observe(ref.current)
                }, delay);
            } else {
                observer.observe(ref.current)
            }
        }
        // Remove the observer as soon as the component is unmounted
        return () => { observer && observer.disconnect() }
    }, [])

    return isIntersecting
}