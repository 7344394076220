import config from "../../../config";

export const cardTypes = {
  GOLD_SUN: 0,
  SILVER_MOON: 1,
  BLUE_NEPTUNE: 2,
  BRONZE_SATURN: 3,
};

const FirstDrop = () => {
  return (
    <div className="row mt-5 d-flex flex-wrap justify-content-between">
      <div className="col-md-6 col-lg-3">
        <div className="nft-card red">
          <h5 className="nft-type-label">POINTS</h5>
          <h4 className="nft-name-label">RED MARS</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS[cardTypes.BRONZE_SATURN]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>6500</h3>
              <h5>0.01 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Red%20Mars"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card blue">
          <h5 className="nft-type-label">STEALS</h5>
          <h4 className="nft-name-label">BLUE NEPTUNE</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS[cardTypes.BLUE_NEPTUNE]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>1286</h3>
              <h5>0.1 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Blue%20Neptune"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card silver">
          <h5 className="nft-type-label">ASSISTS</h5>
          <h4 className="nft-name-label">SILVER MOON</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS[cardTypes.SILVER_MOON]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>152</h3>
              <h5>1 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Silver%20Moon"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card gold">
          <h5 className="nft-type-label">ROOKIE OF THE YEAR</h5>
          <h4 className="nft-name-label">GOLD EVOLVE</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS[cardTypes.GOLD_SUN]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>132</h3>
              <h5>2.6 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Gold%20Evolve"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstDrop;
