import React, { useState } from 'react';
import SecondDrop from '../SecondDrop/SecondDrop';
import Navigation from '../Navigation/Navigation';
import Footer from "../Footer/FooterComponent"
import About from "./components/About"
import FirstDrop from './components/FirstDrop';
import SecondDrops from './components/SecondDrops';
import Modules from "./components/Modules";
import bgImage from '../../assets/images/new-bg-2.jpg';
import osIcon from '../../assets/images/icon_opensea2.png';
import './Home.css';

const Home = (props) => {

    const [isBannerVisible, setIsBannerVisible] = useState(false);

    return (

        <div id="application" style={{ backgroundImage: `url(${bgImage})`, backgroundColor: '#1C2330' }}>

            {/* {isBannerVisible &&

                <div className="top-banner px-5">
                    Missed our LaMelo Collectibles sale? Check out the Collection on
                    <a href="https://opensea.io/collection/lamelo-ball-collectibles" target="_blank" rel="noreferrer">
                        <img src={osIcon} alt="opensea" />
                        OpenSea
                    </a>
                    <span className="close-btn" onClick={(e) => { setIsBannerVisible(false) }}>x</span>
                </div>
            } */}

            <Navigation className={isBannerVisible ? 'banner-visible' : ''} />

            <div className="container my-5 pt-5 h-100 home">
                <h1 className="welcome-title">WELCOME TO LAMELO BALL COLLECTIBLES</h1>
                <p>
                    LaMelo Ball Collectibles is a collection of LaMelo Ball's Official NFTs - unique digital collectibles living on the Ethereum blockchain, Each NFT is supercharged and tethered to NBA phenom LaMelo Ball's statistics, awards and highlights
                </p>
            </div>

            {/* <About /> */}

            <div className="container pb-5">
                <h6 className="mb-1">DYNAMIC NFT</h6>
                <h4 className="mb-1">DROP 001</h4>
                <h6 style={{ fontSize: "0.8rem" }}>Minted June 2021</h6>
                <FirstDrop />
                <p className="text-center sf-pro text-white" style={{ marginTop: "50px", marginBottom: "50px" }}>
                    VERIFIED SMART CONTRACT ADDRESS: {" "}
                    <a href="https://etherscan.io/address/0x139b522955d54482e7662927653abb0bfb6f19ba#code" target="_blank" style={{ color: "#00B5CC", wordBreak: 'break-all' }}>
                        0x139b522955d54482e7662927653abb0bfb6f19ba
                    </a>
                </p>
                <h6 className="mb-1">DYNAMIC NFT</h6>
                <h4 className="mb-1">DROP 002</h4>
                <h6 style={{ fontSize: "0.8rem" }}>Minted February 2022</h6>
                <SecondDrops />
                <p className="text-center sf-pro text-white" style={{ marginTop: "50px", marginBottom: "50px" }}>
                    VERIFIED SMART CONTRACT ADDRESS: {" "}
                    <a href="https://etherscan.io/address/0x4d4d7a1ab7f51947210134e7032729f1020d83f3#code" target="_blank" style={{ color: "#00B5CC", wordBreak: 'break-all' }}>
                        0x4d4d7a1ab7f51947210134e7032729f1020d83f3
                    </a>
                </p>

                {/* <Modules /> */}

            </div>

            <Footer />
        </div>


    );

}

export default Home;