const { Zoom } = require("zoom-next");

export const getTokenIds = async (zoomContract, contract, ownerAddress, numberOfTokens) => {

    const ZoomLibraryInstance = new Zoom();
    const tokenIds = [];
    const item_identifiers = [];
    let callNum = 0;

    for (let i = 0; i < numberOfTokens; i++) {
        // request the token ID
        const tokenIdCall = ZoomLibraryInstance.addCall(
            // the contract we're calling
            contract,
            // the method that is returing our ID
            ["tokenOfOwnerByIndex", [ownerAddress, i]],
            // signature used to decode the result
            "tokenOfOwnerByIndex(address,uint256) returns (uint256)"
            // array of next method calls that will use the result of this current call
        );
        item_identifiers.push(tokenIdCall);
        callNum++;
    }

    // Prepare the binary call
    const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    console.time(`zoomCall_TokenIds_${contract.address}`);
    const combinedResult = await zoomContract.combine(ZoomQueryBinary);
    console.timeEnd(`zoomCall_TokenIds_${contract.address}`);
    ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

    for (let i = 0; i < callNum; i++) {
        let tokenId = ZoomLibraryInstance.decodeCall(item_identifiers[i]).toString();
        tokenIds.push(tokenId);
    }
    return tokenIds;
};


export const zoomFetchTokenUris = async (contract, zoom2, address) => {
    const nt = await contract.balanceOf(address);

    console.log(Number(nt))
    const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
    if (nt > 0) {
        const calls = [];
        for (let i = 0; i < nt; i += 1) {
            const tId = ZoomLibraryInstance.addMappingCountCall(
                contract,
                ['tokenOfOwnerByIndex', [address, i]],
                'tokenOfOwnerByIndex(address,uint256) returns (uint256)',
                [{ contract: contract, mapAndParams: ['tokenURI(uint256)', [i]] }],
            );
            calls.push(tId);

            const tUri = ZoomLibraryInstance.addType5Call(
                contract,
                ['tokenURI(uint256)', [i]],
                'tokenURI(uint256) returns (string)',
            );
            calls.push(tUri);
        }
        const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
        const combinedResult = await zoom2.combine(ZoomQueryBinary);
        ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);
        const tokenIds = [];
        for (let i = 0; i < nt * 2; i += 2) {
            const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
            const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
            tokenIds.push({ id, tokenURI });
        }

        console.log(tokenIds)

        const newMetadata = [];

        if (tokenIds.length > 0) {
            const promises = [];
            for (var i = 0; i < tokenIds.length; i++) {
                const { id, tokenURI } = tokenIds[i];
                promises.push(new Promise(async (resolve) => {
                    const metadata = await getTokenUri(id, tokenURI);
                    newMetadata.push(metadata);
                    resolve();
                }))
            }

            console.time(`fetching tokenUri`)
            await Promise.all(promises)
            console.timeEnd(`fetching tokenUri`)
        }

        return newMetadata.sort((a, b) => {
            return Number(a.tokenId) - Number(b.tokenId)
        })
    }
}

export const checkForgeTokenUsed = async (zoomContract, forgeContract, tokenIds) => {
    const ZoomLibraryInstance = new Zoom();
    const itemIdentifiers = {};
    const unredeemedTokens = [];

    for (let i = 0; i < tokenIds.length; i++) {
        const redeemableCall = ZoomLibraryInstance.addCall(
            forgeContract,
            ["isTokenUsed(uint16)", [tokenIds[i]]],
            "isTokenUsed(uint16) returns (bool)"
        );
        itemIdentifiers[tokenIds[i]] = redeemableCall;
    }

    // Prepare the binary call
    const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    console.time(`zoomCall_isTokenUsed_${forgeContract.address}`);
    const combinedResult = await zoomContract.combine(ZoomQueryBinary);
    console.timeEnd(`zoomCall_isTokenUsed_${forgeContract.address}`);
    ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

    for (let i = 0; i < tokenIds.length; i++) {
        let used = ZoomLibraryInstance.decodeCall(
            itemIdentifiers[tokenIds[i]]
        )[0];
        if (!used) {
            unredeemedTokens.push(tokenIds[i]);
        }
    }
    return unredeemedTokens;
};


export const getTokenUri = async (tokenId, tokenUri) => {
    //console.log(tokenUri)
    const metadata = await fetch(tokenUri)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    if (metadata) {
        if (!metadata.tokenId) {
            metadata.tokenId = tokenId;
        }
        if (!metadata.id) {
            metadata.id = tokenId;
        }
        return metadata;
    } else
        // Fetching metadata fail, return an object anyway
        return {
            tokenId: tokenId,
            id: tokenId
        }
};
