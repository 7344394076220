import React, { useEffect } from "react";
import { SpinnerDotted } from "spinners-react";
import "./ModalPopup.css";

const ModalPopup = ({
    children,
    showModal,
    onClose,
    onSubmit
    }) => {

        useEffect(()=>{
           
        },[showModal])

        return (
            <>

            {showModal&&<div className="modal-container">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body">
                            {children}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-peach btn-outline round mr-auto"  onClick={onClose}>CLOSE</button>
                            <button type="button" className="btn btn-peach round ml-auto"  onClick={onSubmit}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>}

            </>

        );
}

export default ModalPopup;