import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';
import Web3Ctx from '../Context/Web3Ctx';
import Navigation from '../Navigation/Navigation';
import { getBestCardOfOwner, parseRpcError } from '../Utils';
import { getContract } from '../Utils/GetContract';
import './BuyNft.css';
import bgImage from '../../assets/images/BG.jpg';
import ReactMarkdown from 'react-markdown';
import terms from './tc';

const BuyPage = (props) => {
    const {onboard, ethersProvider, address, chainId} = useContext(Web3Ctx);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [cardType, setCardType] = useState('');
    const [cardPrice, setCardPrice] = useState('0');
    const [cardUnitPrice, setCardUnitPrice] = useState();
    const [txInProgress, setTxInProgress] = useState(false);
    const [leftInCurrentBranch, setLeftInCurrentBranch] = useState(0);
    const [ecDiscountTokenId, setEcDiscountTokenId] = useState(null);


    const [remainingFromSubserie, setRemainingFromSubserie] = useState(0);
    const [subserie, setSubserie] = useState(0);
    
    useEffect(()=>{
       // console.log('buy page, MOUNT', typeof props.history.location.state.cardType/* , props.history */);
        window.scrollTo(0,0);
        if(props.history.location.state && typeof props.history.location.state.cardType === 'number' ){

            setTermsVisible(false);
        }else{
             props.history.push({
                pathname: '/'
            }); 
        }
    },[]);

    useEffect(()=>{
        if(address){
            console.log('add',address)
            if(props.history.location.state){
                setCardType(props.history.location.state.cardType);
                getTokenPrice(props.history.location.state.cardType);
            }
        }
    },[address]);
    
    
    const getTokenPrice = async (ctype) => {

        const contract = await getContract('LaMelo',ethersProvider);


        let tokenId = null;
        if(address){
            tokenId=await getBestCardOfOwner(address);
        }

        let discount = 0;
        if(tokenId!==null){
            let id=BigNumber.from(tokenId);
            let c = contract.connect(ethersProvider.getSigner());
            discount = await c.getDiscountPercentage(id).catch(e=>console.log(e));

            if(!discount){
                discount=0;
            }
        }
        setEcDiscountTokenId(tokenId);

        let nxt = await contract.getNextAvailableIdOfType(ctype).catch(e=>{console.log(e)});
        let remaining = await contract.getRemainingCardsOfType(ctype).catch(e=>{console.log(e)});
        let price = await contract.getCardPrice(nxt,ctype,discount).catch(e=>{console.log(e)});

        setCardPrice(price?ethers.utils.formatEther(price):0);
        setCardUnitPrice(price?price:0);
        setLeftInCurrentBranch(Number(remaining));
    }

    const buyCard = async ()=>{
        if(txInProgress){
            return;
        }

        if(!address){
            await onboard.walletSelect();
            return;
        }

        if(!isTermsAccepted){
            toast.warn('Please accept our Terms & Conditions first.');
            return;
        }

        let p=parseFloat(cardPrice);
   

        if(cardPrice===''){
            toast.error('Price field can\'t be empty.');
            return;
        }
        

        if(isNaN(p)){
            toast.error('Wrong price format.');
            return;
        }
        

        if(p===0){
            toast.error('Price can\'t be 0.');
            return;
        }

        

        setTxInProgress(true);


        const payingAmount = ethers.utils.parseEther(p.toString());
     
        const c = await getContract('LaMelo',ethersProvider);
        const ec = c.connect(ethersProvider.getSigner());

        const rem = await ec.getRemainingCardsOfType(cardType).catch(e=>{console.log(e)});;

       let gasLimit = 0;
       
       try{

           if(ecDiscountTokenId){
                gasLimit=await ec.estimateGas.buyCardWithDiscount(cardType,BigNumber.from(ecDiscountTokenId),{value: payingAmount}).catch(e =>{toast.error(e.error.message)});
           }else{
                gasLimit=await ec.estimateGas.buyCard(cardType,{value: payingAmount}).catch(e =>{toast.error(e.error.message)});
           }
       }catch(e){
        console.log('e',e);
      }
       
       setLeftInCurrentBranch(Number(rem));
       //console.log('gaslilmit',gasLimit)
        if(!gasLimit){
            setTxInProgress(false);
            return;
        }

        //console.log('about to buy');

        let tx = null;

       if(ecDiscountTokenId){
            tx=await ec.buyCardWithDiscount(cardType,BigNumber.from(ecDiscountTokenId),{value: payingAmount, gasLimit}).catch(e =>{toast.error(parseRpcError(e.message))});
        }else{
            tx=await ec.buyCard(cardType,{value: payingAmount, gasLimit}).catch(e =>{toast.error(parseRpcError(e.message))});
        }

        //console.log('tx',tx);
        if(tx){
            let res = await tx.wait().catch((e)=>{toast.error(e.reason)});

            //console.log('res',res);
            if(res){

                toast.success('Successfully bought a '+p+' card for '+cardPrice+'ETH');
                setTxInProgress(false);
    
              //  props.history.location.state = null;
                
                props.history.replace({ ...props.history.location, state:null });
    
                props.history.push({
                    pathname: '/wallet'
                });
            }else{
                setTxInProgress(false);
            }

        }
        setTxInProgress(false);
    }

    return(
        <div id="application" style={{backgroundImage:`url(${bgImage})`}}>
            <Navigation/>
            <div className="container">
                {termsVisible &&
                    <div className="row">
                    <div className="col-lg-7 purple-box mx-auto mt-5">                    
                        
                        <div className="t-c ">
                            



                            <h4 className="text-center px-5">LAMELO BALL NFT COLLECTIBLES<br/>TERMS AND CONDITIONS</h4>
                            <div className="tc-content mt-4 px-5 text-justify">

                                <ReactMarkdown children={terms}/>

                            </div>
                        </div>

                    </div>

                </div>}
                
                <div className="row pb-5">
                    <div className="col-lg-7 mx-auto mt-5 purple-box px-5"> 
                        {/* <p><a className="tclink" href="tnc-link" target="_blank">Terms and conditions</a></p> */}
                        <div className="form-group form-check dark">
                            <input type="checkbox" className="form-check-input" id="accept-tc" value={isTermsAccepted} onChange={(e)=>{setIsTermsAccepted(!isTermsAccepted)}}/>
                            <label className="form-check-label tnclink text-purple" htmlFor="dis-accept-tc" onClick={()=>setTermsVisible(true)}>I agree to the Terms &amp; Conditions</label>
                        </div>

                        <div className="form-group  dark">
                            <label className="" htmlFor="buy-amount">Number of cards:</label>
                            <select className="form-control" onChange={(e)=>{setCardPrice(ethers.utils.formatEther(cardUnitPrice.mul(e.target.value)))}}>
                                {[1,2,3,4,5,6,7,8,9,10].map((k,i)=>{return(
                                    <option key={'o'+k} value={k}>{k}</option>
                                )})}
                            </select>
                        </div>

                        <div className="form-group  dark">
                            <label className="" htmlFor="buy-amount">Amount to pay: (ETH)</label>
                            <input type="text" className="form-control" id="buy-amount" value={cardPrice} onChange={(e)=>{setCardPrice(e.target.value)}}/>
                        </div>
                        
                        <div className="form-group  dark mb-5">
                            <p>The amount of ether indicated in the box will be sent to the contract, and the maximum amount of cards will be purchased. Any remainder will be refunded.</p>
                            <p>The amount does not include gas (transaction) cost. That will be calculated and added to the amount by the wallet.</p>
                            <p className="mb-0">{leftInCurrentBranch.toLocaleString()} card{leftInCurrentBranch==1?'':'s'} available.</p>
                       
                        </div>
                        
                        <div className="form-group d-flex justyfy-content-between pb-5">
                            <button className="btn btn-peach btn-outline round mr-auto"  onClick={(e)=>{props.history.goBack()}}>BACK</button>
                            <button className={`btn btn-peach round  ml-auto ${!isTermsAccepted?'disabled':''}`} onClick={buyCard}>
                            <SpinnerDotted enabled={txInProgress} className="mr-2" size={15} thickness={160} speed={100} color="inherit" />
                                BUY
                            </button>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
}
export default BuyPage;