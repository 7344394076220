import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './EnterTheDrop.css';
import Web3Ctx from '../Context/Web3Ctx';
import { getContract } from '../Utils/GetContract';
import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';

import bgImage from '../../assets/images/drop_bg.jpg';

import config from '../../config';
import Countdown from '../common/Countdown';
import { Zoom } from "zoom-next";
import { BitArray } from 'ec-util';
import routes from '../../Routes';
import useInterval from '../common/useInterval';
import Address from '../common/Address';
import { ethers } from 'ethers';

const EnterTheDrop = (props) => {

    const {onboard, address, ethersProvider} = useContext(Web3Ctx);
    const [isConnected,setIsConnected] = useState(false);
    const [connectedAddress, setIsConnectedAddress] = useState('0x0');
    const [ec,setEc] = useState(null);
    const [raffle,setRaffle] = useState(null);
    
    const [cards, setCards] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    
    const [cardArtwork,setCardArtwork] = useState('');

    const [participants,setParticipants] = useState({ mars:0,
        neptune:0,
        moon:0});

    const [ownerEntries, setOwnerEntries] = useState({ mars:[],
        neptune:[],
        moon:[]});  

    const [eligibleCards,setEligibleCards] = useState({ mars:[],
                                                        neptune:[],
                                                        moon:[]});
                                                        

    const [zoom2,setZoom2] = useState(null);
    
    const [raffleStart,setRaffleStart] = useState(null);
    const [raffleEnd,setRaffleEnd] = useState(null);
    const [currentTime,setCurrentTime] = useState(0);
    const [winners,setWinners] = useState([]);
    const [txInProgress,setTxInProgress] = useState(false);
    const [txHash,setTxHash] = useState('');
    

    useInterval(()=>{
        tick();
    },1000);

    const tick = ()=>{
        setCurrentTime(Date.now());
    }
   
    useEffect(()=>{
        console.log('DROP');

        const initContract=async()=>{
           // console.log('WALLET [ethersProvider] running', ethersProvider)
            const contract = await getContract('LaMelo',ethersProvider).catch(e=>console.log('err:',e));
            setEc(contract);

            let Zoom2Contract = await getContract('Zoom2', ethersProvider);

           // console.log('zoom contract',Zoom2Contract);
            if (Zoom2Contract) {
                setZoom2(Zoom2Contract);
            } else {
                toast.error('Could not initialise Zoom2 Contract');
            }

            const raffleContract = await getContract('LaMeloRaffle',ethersProvider).catch(e=>console.log('err:',e));
            setRaffle(raffleContract);
        }

        if(ethersProvider){
            initContract();
        }
    },[ethersProvider]);

    useEffect(()=>{

        if(ec && zoom2 && raffle && address){
            //console.log('about to get tokens [ec,address]',ec,address);
            setIsConnected(true);
            getTokens();
        }else{
            setIsConnected(false);
        }

        //console.log('wallet [ec,address]');
    },[ec,zoom2,raffle,address]);



    const getTokens = async () => {
        if(!ec){
            toast.error('Contract not found');
            return;
        }

   // console.log('getting tokens');
        setIsLoading(true);

        const dropStart = await raffle.startTime(); 
        const dropEnd = await raffle.endTime(); 
        if(dropStart){
            setRaffleStart(dropStart.toNumber()*1000);
        }

        if(dropEnd){
            setRaffleEnd(dropEnd.toNumber()*1000);
        }

      //  console.log('getting the stats');

      //  let stats = await raffle.getStats(address); 
        let stats = await raffle.getStats(); 

      //  console.log('stats',stats);

        let pnums = {
            mars: 0,
            neptune: 0,
            moon: 0
        };

/* 
        let onums = {
            mars: 0,
            neptune: 0,
            moon: 0
        }; */

        if(stats._poolCounts && stats._poolCounts.length===3){
            pnums.mars = stats._poolCounts[0];
            pnums.neptune = stats._poolCounts[1];
            pnums.moon = stats._poolCounts[2];
            setParticipants(pnums);
        }

      /*   if(stats._myCounts && stats._myCounts.length===3){
            onums.mars = stats._myCounts[0];
            onums.neptune = stats._myCounts[1];
            onums.moon = stats._myCounts[2];
            setOwnerEntries(onums);
        } */

        if(stats._winners && stats._winners.length===3){
            setWinners(stats._winners);

        }

        



        
        const tokenData = new BitArray.fromUint8Array(stats._tokenData);

        const ZoomLibraryInstance = new Zoom();
        
        const numberOfTokens = await ec.balanceOf(address);


        if(numberOfTokens.toNumber() > 0){
            let tokenIds = [];
            let ownedTokens = [];
             
            for(let i = 0; i<numberOfTokens; i++){
                const tokenIdCall = ZoomLibraryInstance.addCall(
                    ec,
                    ["tokenOfOwnerByIndex", [address, i]],
                    "tokenOfOwnerByIndex(address,uint256) returns (uint256)"
                );
                ownedTokens.push(tokenIdCall);
            }

            const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

          //  console.log("======== ZOOM CALL START ============" );
          //  console.time('zoomCall');
            const combinedResult = await zoom2.combine( ZoomQueryBinary );
         //   console.timeEnd('zoomCall');
          //  console.log("======== ZOOM CALL END ==============" );

            ZoomLibraryInstance.resultsToCache( combinedResult, ZoomQueryBinary );

            let myCounts = {
                mars: [],
                neptune: [],
                moon: []
            };

            for(let i = 0; i < numberOfTokens; i++) {
                let tokenId = ZoomLibraryInstance.decodeCall(ownedTokens[i]).toString();

                if(!tokenData.get(tokenId)){
                    tokenIds.push(tokenId);
                }else{
                    if(tokenId>500 && tokenId <=1500){
                        myCounts.moon.push(Number(tokenId));
                    }
                    if(tokenId>1500 && tokenId <=3500){
                        myCounts.neptune.push(Number(tokenId));
                    }
                    if(tokenId>3500){
                        myCounts.mars.push(Number(tokenId));
                    }
                }
            }

            setOwnerEntries(myCounts);

            setCards(tokenIds);

           // console.log('tokenIDs',tokenIds)

            let eligibles = {
                mars: [],
                neptune: [],
                moon: []
            };

            for(let j = 0; j<tokenIds.length;j++){

            
                let id = tokenIds[j];
                if(id>500 && id <=1500){
                    eligibles.moon.push(Number(id));
                }
                if(id>1500 && id <=3500){
                    eligibles.neptune.push(Number(id));
                }
                if(id>3500){
                    eligibles.mars.push(Number(id));
                }
            }

            setEligibleCards(eligibles);
            
        }else{
            setEligibleCards({
                mars: [],
                neptune: [],
                moon: []
            });

            setOwnerEntries({ mars:[],
                neptune:[],
                moon:[]});

           setCards([]);
        }
        setIsLoading(false);


        let hash=null;
        if(window && window.localStorage){

           hash = localStorage.getItem('txhash');
        }

        if(hash && hash !== ''){
            setTxHash(hash);

            let tx=await ethersProvider.getTransaction(hash);

            if(tx){
                setTxInProgress(true);
                let res = await tx.wait().catch((e)=>{toast.error(e.reason)});
                if(res){
                    
                    toast.success('Success');
                    localStorage.setItem('txhash','');
                    getTokens();
                    setTxInProgress(false);
                }
            }


        }


    }

    const handleEnterTheDrop = async ()=> {
        
      //  console.log('eligiblez',eligibleCards);
        let ids = [].concat(eligibleCards.moon,eligibleCards.neptune,eligibleCards.mars);
       // ids=[1654,1655,4135,4136,4137,4138,4139,4140,4141,4142,4143,4144,4909,4910,4911,4912,4913,4914,4915,4916,4917,4918,4919,4920,4921,4922,4923,4924,4925,4926,4927,4928,4929,4930,4931,4932,4933,4934,4935,4936,4937,4938];
       // console.log('idz',ids);



        setTxInProgress(true);

        let rc = raffle.connect(ethersProvider.getSigner());

        let gasLimit = 0;//200000+ids.length*50000;
        
        try{
            gasLimit=await rc.estimateGas.registerForDrop(ids).catch(e =>{toast.error(e.error.message)});
        }catch(e){
            console.log('e',e);
        }

      
      //  console.log('GL',gasLimit);
       
       if(gasLimit){
           let tx = null;
           tx = await rc.registerForDrop(ids, {gasLimit}).catch(e =>{toast.error(e.message)});
          //  console.log('TX',tx);

            if(tx){

                setTxHash(tx.hash);
                localStorage.setItem('txhash',tx.hash);

                let res = await tx.wait().catch((e)=>{toast.error(e.reason)});
                if(res){
                    toast.success('Success');
                    localStorage.setItem('txhash','');
                    getTokens();
                    setTxInProgress(false);

                   /*  props.history.push({
                        pathname: '/the-drop'
                    }); */
                }
            }
        }
        setTxInProgress(false);


        
        
        
        
        
    }
    
    const connect = async () => {
        if (onboard) {
            await onboard.walletSelect();
        }
    }

    return (
        <div id="application" style={{backgroundImage:`url(${bgImage})`, backgroundColor:'#1C2330'}}>
            <Navigation {...props}/>
            <div className="container">
                <div className="row padd px-2">
                    <div className="col-lg-11 mx-auto my-5 py-3  drop-enter-container">

                        <div className="row">
                            <div className="col-md-8 px-3">
                                <h1 className="ge-title">GOLD EVOLVE DROP</h1>
                                <p>Owners of Red Mars, Blue Neptune, and/or Silver Moon can enter to win a Gold Evolve –– one winner will be chosen from each tier using Chainlink VRF. To enter, you need to send a transaction and all of your eligible cards from your wallet will be submitted in the raffle. Owning multiple of the same tokens within one tier (i.e. 5 Red Mars) will increase the likelihood of you being chosen within that tier.</p>
                                                
                            {isConnected?
                            <>
                                {isLoading?
                                    <div className="col-lg-12 mt-5 mb-5 text-center">
                                        <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                    </div>

                                :
                                <>
                                
                                        <div className="row">
                                            <div className="col-md-12 text-left mt-4 mb-3">
                                                
                                                {(raffleStart < currentTime && raffleEnd>currentTime) &&
                                                <>{/* Drop active */}
                                                    <div className="row text-left">
                                                        <div className="col-md-4 mt-4">
                                                            <h5 className="counter-title">Participants:</h5>
                                                            <p className="counter-values mb-0">Mars: {participants.mars} </p>
                                                            <p className="counter-values mb-0">Blue Neptune: {participants.neptune} </p>
                                                            <p className="counter-values mb-0">Silver Moon: {participants.moon} </p>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <h5 className="counter-title">Your Entries:</h5>
                                                            <p className="counter-values mb-0">Mars: {ownerEntries.mars.length}</p>
                                                            <p className="counter-values mb-0">Blue Neptune: {ownerEntries.neptune.length}</p>
                                                            <p className="counter-values mb-0">Silver Moon: {ownerEntries.moon.length}</p>
                                                        </div>

                                                        <div className="col-md-4 mt-4">
                                                            <h5 className="counter-title">Eligible NFTs:</h5>
                                                            <p className="counter-values mb-0">Mars: {eligibleCards.mars.length}</p>
                                                            <p className="counter-values mb-0">Blue Neptune: {eligibleCards.neptune.length}</p>
                                                            <p className="counter-values mb-0">Silver Moon: {eligibleCards.moon.length}</p>
                                                        </div>
                                                    </div>

                                                    <p className="os  text-left my-4">
                                                        <a href="https://opensea.io/collection/lamelo-ball-collectibles" target="_blank" rel="noreferrer" >Want more chances? <span className="text-white">Click here</span> to buy LaMelo Collectibles</a>
                                                    </p>

                                                    {(eligibleCards.moon.length > 0 || eligibleCards.neptune.length >0 || eligibleCards.mars.length >0) && 
                                                        <div  className="text-left my-5">
                                                            {txInProgress?
                                                                <>
                                                                    <SpinnerDotted enabled={true} size={35} thickness={160} speed={200} color="#fff" />
                                                                    <div className="os mt-5">
                                                                        <a className="" href={`https://etherscan.io/tx/${txHash}`} target="_blank" rel="noreferrer">View transaction on etherscan</a>
                                                                    </div>
                                                                </>
                                                                :
                                                                <button className="btn btn-outline" onClick={handleEnterTheDrop}>Enter the Drop</button>
                                                            }
                                                        </div>}


                                                    <div  className="text-left mt-5 dw text-white d-flex flex-row justify-content-start">
                                                        <span className="counter-label">ENDS IN </span> <Countdown startDate={raffleEnd} short small/>
                                                    </div>
                                                </>
                                                }

                                                {(currentTime>0 && raffleStart > currentTime) &&
                                                <>
                                                    <div  className="text-center my-5">
                                                        <h4>Drop will start in</h4>
                                                        <Countdown startDate={raffleStart}/>
                                                    </div>
                                                </>
                                                }

                                                {raffleEnd < currentTime &&
                                                <>
                                                    



                                                    {winners[0]==="0x0000000000000000000000000000000000000000" ?
                                                        <>
                                                            <div  className="text-center my-5">

                                                                <h5 className="mb-5">
                                                                    The drop finished. Winner selection in progress.
                                                                </h5>
                                                                <p className="mt-5">
                                                                 <SpinnerDotted enabled={true} size={35} thickness={160} speed={200} color="#fff" />
                                                                </p>

                                                            </div>
                                                        </>
                                                        :
                                                            <div  className="text-center my-5">

                                                                <h5 className="mb-5">
                                                                    The drop finished. The winners are:
                                                                </h5>

                                                                {winners.map((w,i)=>{return (
                                                                    <p key={w+i}>
                                                                    <Address address={w} blockie/>

                                                                    </p>
                                                                )})

                                                                }
                                                            </div>
                                                    }


                                                    



                                                </>
                                                }



                                            </div>
                                        </div>
                                    </>


                                }
                                </>
                            
                            :
                            
                            <div className="col-md-10 mx-auto mt-5  text-center">
                                <h5>In order to see your eligible tokens, you need to connect your wallet</h5>

                                <button className="btn btn-peach btn-outline mx-auto mt-5 px-3" onClick={()=>{connect()}}>CONNECT</button>
                            </div>
                            }

                                <p className="mt-4">The raffle entries will be open for a duration of four weeks from opening date. Upon completion of the raffle, the Gold Evolve NFT will be sent to the wallet address that owns the card selected as the winner.</p>

                                <h5 className="dw owner-benefits">Gold Evolve Owner Benefits</h5>

                                <ul className="rewards-list">
                                    <li>Official Physical LaMelo Ball Gold Collectible Card</li>
                                    <li>Guaranteed $750+ in Endorsement Deal rewards</li>
                                    <li>Guaranteed Official LaFrance Merchandise</li>
                                    <li>Chances to win NBA Game Tickets valued up to $5,000</li>
                                    <li>50% chance to receive autographed memorabilia</li>
                                    <li>Exclusive access to scheduled group chats with LaMelo Ball</li>
                                </ul>
                            </div>


                            <div className="col-md-4">
                                <div className="sale-card mb-3">
                                    <div dangerouslySetInnerHTML={
                                    { __html: `
                                                <video
                                                    loop
                                                    muted
                                                    autoplay
                                                    playsinline
                                                    src="${config.NFT_ANIMS[0]}"
                                                />`}}>
                                    </div>

                                </div>    
                            </div>


                        </div>

                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnterTheDrop;