import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './TheDrop.css';
import Web3Ctx from '../Context/Web3Ctx';
import { getContract } from '../Utils/GetContract';
import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';

import bgImage from '../../assets/images/dropRoomBg.jpg';

import config from '../../config';
import Countdown from '../common/Countdown';
import { Zoom } from "zoom-next";
import { BitArray } from 'ec-util';
import routes from '../../Routes';
import useInterval from '../common/useInterval';
import Address from '../common/Address';

import goldEvolveIcon from  '../../assets/images/dropIcons/goldevolveicon.jpg';
import hokWearableIcon from  '../../assets/images/dropIcons/wearableIcon.jpg';

const TheDrop = (props) => {

    const {onboard, address, ethersProvider} = useContext(Web3Ctx);
    const [isConnected,setIsConnected] = useState(false);
    const [connectedAddress, setIsConnectedAddress] = useState('0x0');
    const [ec,setEc] = useState(null);
    const [raffle,setRaffle] = useState(null);
    
    const [cards, setCards] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    
    const [cardArtwork,setCardArtwork] = useState('');

    const [participants,setParticipants] = useState({ mars:0,
        neptune:0,
        moon:0});

    const [ownerEntries, setOwnerEntries] = useState({ mars:0,
        neptune:0,
        moon:0});  

    const [eligibleCards,setEligibleCards] = useState({ mars:[],
                                                        neptune:[],
                                                        moon:[]});  

    const [zoom2,setZoom2] = useState(null);
    
    const [raffleStart,setRaffleStart] = useState(null);
    const [raffleEnd,setRaffleEnd] = useState(null);
    const [currentTime,setCurrentTime] = useState(0);
    const [winners,setWinners] = useState([]);

    const [selectedFilter,setSelectedFilter] = useState(0);

    const [selectedDrops, setSelectedDrops] = useState([]);
    const [allDrops, setAllDrops] = useState([]);

    const dropType = {
        RAFFLE:1,
        STATS:2,
        REDEEMABLE:3
    }
    
    const data = [
                        {
                            type: dropType.RAFFLE,
                            name: 'Gold Evolve Raffle',
                            description: 'Enter to win Gold Evolve LaMelo Collectible edition 500, 499, 498. You must own at least one Red Mars, Blue Neptune or Silver Moon to enter.',
                            image: goldEvolveIcon,
                            startTime: null,
                            endTime: null
                        }/* ,
                        {
                            type: dropType.REDEEMABLE,
                            name: 'Hok Wearable',
                            description: 'Redeem an official House of Kibaa x LaMelo Ball wearable Jersey. You must own at least one Gold Evolve to redeem.',
                            image: hokWearableIcon,
                            startTime: null,
                            endTime: null
                        } */

    ];

    useInterval(()=>{
        tick();
    },1000);

    const tick = ()=>{
        setCurrentTime(Date.now());
    }

    useEffect(()=>{
        setAllDrops(data);
    },[])
   
    useEffect(()=>{


        console.log('DROP');
        setCurrentTime(Date.now());
       
        

        const initContract=async()=>{
           // console.log('WALLET [ethersProvider] running', ethersProvider)
            const contract = await getContract('LaMelo',ethersProvider).catch(e=>console.log('err:',e));
            setEc(contract);

            let Zoom2Contract = await getContract('Zoom2', ethersProvider);

          //  console.log('zoom contract',Zoom2Contract);
            if (Zoom2Contract) {
                setZoom2(Zoom2Contract);
            } else {
                toast.error('Could not initialise Zoom2 Contract');
            }

            const raffleContract = await getContract('LaMeloRaffle',ethersProvider).catch(e=>console.log('err:',e));
            setRaffle(raffleContract);

            
        }

        if(ethersProvider){
            initContract();
        }
    },[ethersProvider]);

    useEffect(()=>{

        if(ec && zoom2 && raffle){
            //console.log('about to get tokens [ec,address]',ec,address);
            setIsConnected(true);
            getTokens();
        }else{
            setIsConnected(false);
        }

        //console.log('wallet [ec,address]');
    },[ec,zoom2,raffle]);



    const getTokens = async () => {
        if(!raffle){
            toast.error('Contract not found');
            return;
        }

   // console.log('getting tokens');
        setIsLoading(true);

        const dropStart = await raffle.startTime(); 
        const dropEnd = await raffle.endTime(); 

  //   console.log('start time', dropStart.toNumber());

        let d = allDrops;

        if(dropStart){
            setRaffleStart(dropStart.toNumber()*1000);

            d[0].startTime=dropStart.toNumber();
        }else{
            d[0].startTime=null;
        }

        if(dropEnd){
            setRaffleEnd(dropEnd.toNumber()*1000);
            d[0].endTime=dropEnd.toNumber();
        }else{
            d[0].endTime=null;
        }

        setAllDrops(d);

        selectFilter(0);

        setIsLoading(false);
    }




    const handleEnterTheDrop = (idx)=>{
        //TEMP!!!
        if(selectedDrops[idx].type !==dropType.RAFFLE){return;}
        
        props.history.push({
            pathname: '/the-drop/enter'
        });
    }

    const selectFilter = (idx) => {
        setSelectedFilter(idx);
        if(idx === 0){
            setSelectedDrops(allDrops);
        }else{
            let d = [];

            for(let i = 0; i<allDrops.length;i++){
                if(allDrops[i].type === idx){
                    d.push(allDrops[i]);
                }
            }

          //  console.log(d,allDrops);
            setSelectedDrops(d);
        }


        
    }


    return (
        <div id="application" style={{backgroundImage:`url(${bgImage})`, backgroundColor:'#1C2330'}}>
            <Navigation {...props}/>
            <div className="container">
                <div className="row padd">
                    <div className="col-lg-12 mt-5">
                        <h1 className="text-left bold-header dr-title">DROP ROOM</h1>
                        <p>An exclusive destination for LaMelo Ball Collectible token owners to participate <br/>in raffles, prediction games, and in-game performance-based events.</p>

                        <ul className="filters dw list-group d-flex flex-md-row  flex-sm-column flex-wrap">
                            {
                                ['All','Raffles','Stat-Based','Redeemables'].map((label,i)=>{return(
                                                        <li className={`filter-item d-flex text-sm-center px-4 py-2 mr-4 ${selectedFilter===i?'active':''}`} onClick={(e)=>selectFilter(i)} key={'lbl'+i}>
                                                            {label}
                                                        </li>
                                                    )})                                
                            }

                        </ul>

                        <div className="drop-container row mt-5">
                            {selectedDrops.length===0?
                                <>
                                {!isLoading && <h1 className="coming-soon text-center">COMING SOON</h1>}
                                {isLoading && <div className="col-md-6 mx-auto"><SpinnerDotted enabled={true} size={35} thickness={160} speed={200} color="#fff" /></div>}


                                </>

                                 :

                                <>
                                {
                                    selectedDrops.map((drp,i)=>{return(
                                                            <div className={`drop-item col-md-5 d-flex mx-2 my-4 py-3 ${drp.type===dropType.RAFFLE?'raffle':drp.type===dropType.REDEEMABLE?'redeemable':''}`} key={'di'+i}>
                                                                <div className="drop-content col-8 p-0">
                                                                    <h4 className="drop-item-title">
                                                                        {drp.name}
                                                                    </h4>
                                                                    <p>{drp.description}</p>
                                                                    <button className="btn btn-outline" disabled={!drp.startTime || (drp.startTime && Number(drp.startTime)*1000 > currentTime)} onClick={(e)=>{handleEnterTheDrop(i)}}>{drp.startTime?'ENTER THE DROP':'COMING SOON'}</button>
                                                                </div>
                                                                <div className="drop-image col-4 pr-0 d-flex">
                                                                    <img className="w-100" src={drp.image} alt={drp.name}/>
                                                                </div>
                                                                {(drp.startTime && Number(drp.startTime)*1000 > currentTime ) &&
                                                                    <div className="drop-counter dw d-flex">

                                                                        <span className="counter-label">STARTS IN</span> <Countdown className="d-inline" startDate={drp.startTime*1000} hoursMinutesOnly={false} short small/>
                                                                    </div>
                                                                }

                                                                {(drp.startTime && Number(drp.startTime)*1000 < currentTime  && Number(drp.endTime)*1000 > currentTime) &&
                                                                    <div className="drop-counter dw d-flex">

                                                                        <span className="counter-label">ENDS IN</span> <Countdown className="d-inline" startDate={drp.endTime*1000} hoursMinutesOnly={false} short small/>
                                                                    </div>
                                                                }

                                                                {(drp.startTime &&  Number(drp.endTime)*1000 < currentTime) &&
                                                                    <div className="drop-counter dw d-flex">

                                                                        <span className="counter-label">FINISHED</span>
                                                                    </div>
                                                                }




                                                            </div>
                                                        )})                                
                                }

                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TheDrop;