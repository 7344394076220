import React, { useContext, useEffect, useState, useRef } from 'react';

import Web3Ctx from '../Context/Web3Ctx';
import { checkForgeTokenUsed, getTokenIds } from '../../utils/zoom';
import { getContract } from '../Utils/GetContract';
import EmbeddedCard from '../common/EmbeddedCard';
import useContainerDimensions from '../common/useContainerDimensions';
import RippleButton from "../common/RippleButton"

import closeIcon from "../../assets/images/close-icon-white.svg";
import './Forge.css';

import { SpinnerDotted } from 'spinners-react';

import { toast } from 'react-toast';

const getCardType = (id) => {
    const value = Number(id)
    return value <= 500 ? 0 : value <= 1500 ? 1 : value <= 3500 ? 2 : 3
}

const getIdByType = (id, type) => {
    if (type == 0) {
        return id <= 500
    } else if (type == 1) {
        return id > 500 && id <= 1500
    } else if (type == 2) {
        return id > 1500 && id <= 3500
    } else return id > 3500
}

const DROPPABLE_GOLD = "target-gold"
const DROPPABLE_SILVER = "target-moon"
const DROPPABLE_BLUE = "target-neptune"
const DROPPABLE_RED = "target-mars"

const droppableIndex = [DROPPABLE_GOLD, DROPPABLE_SILVER, DROPPABLE_BLUE, DROPPABLE_RED]

const initialItems = {
    eligibleTokens: {
        [DROPPABLE_GOLD]: [],
        [DROPPABLE_SILVER]: [],
        [DROPPABLE_BLUE]: [],
        [DROPPABLE_RED]: [],
    },
    [DROPPABLE_GOLD]: null,
    [DROPPABLE_SILVER]: null,
    [DROPPABLE_BLUE]: null,
    [DROPPABLE_RED]: null,
}

const Card = ({ tokenId, cardType, playVideo }) => {
    return <EmbeddedCard
        tokenId={tokenId}
        cardType={cardType}
        disableVideo={!playVideo}
        disableTraits={true}
        disableStats={true}
        showFrontId={true}
        onScreenDelay={500}
    />
}

const Forge = ({ onClickForge }) => {

    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);
    const [ec, setEc] = useState(null);
    const [forgeContract, setForgeContract] = useState(null);
    const [zoomContract, setZoomContract] = useState(null);

    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [displayTypeId, setDisplayTypeId] = useState(null);

    const [items, setItems] = useState(initialItems);

    const uploadCardRef = useRef()
    const { width, height } = useContainerDimensions(uploadCardRef)

    useEffect(() => {
        console.log('FORGE');
        const initContract = async () => {
            const contract = await getContract('LaMelo', ethersProvider).catch(e => console.log('err:', e));
            const forgeContract = await getContract('LameloSaleForge', ethersProvider).catch(e => console.log('err:', e));
            const zoomContract = await getContract('Zoom2', ethersProvider).catch(e => console.log('err:', e));
            setEc(contract);
            setForgeContract(forgeContract);
            setZoomContract(zoomContract)
        }

        if (ethersProvider) {
            initContract();
        }
    }, [ethersProvider]);

    useEffect(() => {
        if (ec && forgeContract && zoomContract && address) {
            setIsConnected(true);
            getTokens();
        } else {
            setIsConnected(false);
        }
    }, [ec, address, forgeContract, zoomContract]);


    const getTokens = async () => {
        if (!ec) {
            toast.error('Contract not found');
            return;
        }
        setIsLoading(true);

        const numberOfTokens = await ec.balanceOf(address);

        if (numberOfTokens.toNumber() > 0) {
            let tokenIds = await getTokenIds(
                zoomContract,
                ec,
                address,
                numberOfTokens
            );

            let eligibleTokens = await checkForgeTokenUsed(
                zoomContract,
                forgeContract,
                tokenIds
            );


            eligibleTokens = eligibleTokens
                .sort((a, b) => a - b)
                .map(value => String(value));

            setCards(eligibleTokens);

            setItems({
                eligibleTokens: {
                    [DROPPABLE_GOLD]: filterTokensByType(eligibleTokens, 0),
                    [DROPPABLE_SILVER]: filterTokensByType(eligibleTokens, 1),
                    [DROPPABLE_BLUE]: filterTokensByType(eligibleTokens, 2),
                    [DROPPABLE_RED]: filterTokensByType(eligibleTokens, 3),
                },
                [DROPPABLE_GOLD]: null,
                [DROPPABLE_SILVER]: null,
                [DROPPABLE_BLUE]: null,
                [DROPPABLE_RED]: null,
            })

        } else {
            setCards([]);
            setItems(initialItems)
        }
        setIsLoading(false);
    }

    const connect = async () => {
        if (onboard) {
            await onboard.walletSelect();
        }
    }

    const filterTokensByType = (tokens, type) => {
        return tokens.filter((cardId) => {
            return getIdByType(Number(cardId), type)
        })
    }

    const mint = () => {
        if (items[DROPPABLE_GOLD] && items[DROPPABLE_SILVER] && items[DROPPABLE_BLUE] && items[DROPPABLE_RED]) {
            onClickForge(items[DROPPABLE_GOLD], items[DROPPABLE_SILVER], items[DROPPABLE_BLUE], items[DROPPABLE_RED])
        } else {
            console.log("Missing cards for forging")
        }
    }

    const renderDroppableUploadCard = (droppableId, cardType) => {

        const tokenId = items[droppableId];

        const borderStyle = {
            border: `${tokenId ? "none" : `2px dashed ${cardType === 0 ? '#edd923' : cardType === 1 ? '#989898' : cardType === 2 ? '#3d44eb' : '#eb4e3d'}`}`
        }

        const backgroundStyle = {
            background: `${cardType === 0 ? '#3e3d05cc' : cardType === 1 ? '#2424249c' : cardType === 2 ? '#040f609c' : '#610101a6'}`
        }

        const style = {
            ...borderStyle,
            ...backgroundStyle,
        }
        return (
            <div className={`d-flex flex-column justify-content-center ${droppableId}`} style={style} onClick={() => selectForgeCard(droppableIndex[cardType])}>
                {
                    tokenId ?
                        <>
                            <Card tokenId={tokenId} cardType={cardType} playVideo={true} />
                            <img className="target-remove" src={closeIcon} onClick={(e) => handleRemoveCard(e, droppableId)}></img>
                        </>

                        :
                        <div className="target-help px-2">
                            Pick your card here
                        </div>
                }
            </div>
        )
    }

    const handleRemoveCard = (e, typeId) => {
        e.stopPropagation();
        if (items[typeId]) {
            setItems((items) => {
                return {
                    ...items,
                    [typeId]: null
                }
            })
        }
    }

    const selectForgeCard = (typeId) => {
        setDisplayTypeId(typeId)
    }

    const hideForgeCard = (e) => {
        setDisplayTypeId(null)
    }

    const handleCardSelected = (cardId, cardType) => {

        const typeId = droppableIndex[cardType];

        if (items[typeId]) {
            if (items[typeId] !== cardId) {
                setItems((items) => {
                    return {
                        ...items,
                        [typeId]: cardId,
                    };
                });
            }
        } else {
            setItems((items) => {
                return {
                    ...items,
                    [typeId]: cardId,
                };
            });
        }

        setDisplayTypeId(null)
    }

    return (
        <div id="forge" className="container pb-5">
            <div className="row">
                <div className="col-lg-12 mt-5">
                    <div id="forgeField" className="row position-relative overflow-hidden">
                        <div className="col-lg-12 forge-container">
                            <div className="dots-left"></div>
                            <div className="dots-right"></div>
                            <div className="dots-top"></div>
                            <div className="dots-bottom"></div>

                            <div className="target-box blue" ref={uploadCardRef}>
                                <h5 className="title mb-0">BLUE NEPTUNE</h5>
                                {renderDroppableUploadCard(DROPPABLE_BLUE, 2)}
                            </div>
                            <div className="target-box red">
                                <h5 className="title mb-0">RED MARS</h5>
                                {renderDroppableUploadCard(DROPPABLE_RED, 3)}
                            </div>
                            <div className="target-box gold">
                                <h5 className="title mb-0">GOLD EVOLVE</h5>
                                {renderDroppableUploadCard(DROPPABLE_GOLD, 0)}
                            </div>
                            <div className="target-box silver">
                                <h5 className="title mb-0">SILVER MOON</h5>
                                {renderDroppableUploadCard(DROPPABLE_SILVER, 1)}
                            </div>

                            <div className="target-box minter">
                                <h5 className="mt-2 mb-0">??????</h5>
                                <div className="minted-holder">
                                    <div className="video-container">
                                        <video
                                            loop
                                            muted
                                            autoPlay
                                            playsInline
                                            src="https://ether-cards.mypinata.cloud/ipfs/QmTQCZRS3KtErE7nQQG3QXZR3j9QSwi9SuThjy49dUMSX4"
                                        ></video>
                                    </div>
                                </div>
                                <RippleButton onClick={mint}>MINT</RippleButton>
                            </div>

                            <RippleButton className="minter-btn" onClick={mint}>MINT</RippleButton>
                        </div>

                        <div className={`forge-token-container ${displayTypeId != null ? "show" : ""}`}>


                            <div className="forge-token-header text-center">
                                <h5 className="py-1 my-0" onClick={hideForgeCard}>TOKENS IN YOUR WALLET</h5>
                                <div className="forge-close-btn">
                                    <img src={closeIcon} onClick={hideForgeCard}></img>
                                </div>
                            </div>


                            {isLoading ?
                                <div className="col-lg-12 mt-5 mb-5 text-center">
                                    <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                </div>

                                :
                                displayTypeId != null &&
                                <div className="tokens-container">
                                    {
                                        items.eligibleTokens[displayTypeId].map((value, index) => {
                                            const cardType = getCardType(value);

                                            const selected = items[displayTypeId] == value;
                                            return <div key={value} className={`card-container ${selected ? "selected" : ""}`}
                                                onClick={() => handleCardSelected(value, cardType)}>
                                                <Card tokenId={value} cardType={cardType} />
                                            </div>
                                        })
                                    }

                                    {items.eligibleTokens[displayTypeId].length == 0 &&
                                        <div className="col-md-8 mx-auto mt-5 text-center">
                                            <h5>Sorry, there are no eligible tokens in your wallet. You can get some from <a href="https://opensea.io/collection/lamelo-ball-collectibles?" target="_blank">here</a>.</h5>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>

                </div>

            </div>

            {!isConnected &&
                <div className="row mt-4">
                    <div className="col-md-6 mx-auto text-center">
                        <h5>In order to use the forge, you need to connect your wallet</h5>

                        <button className="btn btn-peach btn-outline mx-auto mt-3 px-3" onClick={() => { connect() }}>CONNECT</button>
                    </div>
                </div>
            }
        </div>

    );
}

export default Forge;