import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/FooterComponent";
import bgImage from "../../assets/images/new-bg-2.jpg";
import "./Tnc.css";

const Tnc = () => {
  return (
    <div
      id="application"
      style={{ backgroundImage: `url(${bgImage})`, backgroundColor: "#1C2330" }}
    >
      <Navigation />
      <div id="tnc" className="container mt-5" style={{ color: "#fff" }}>
        <p dir="ltr">TERMS OF SERVICE</p>
        <p dir="ltr">Last Revised on November, 22 2021</p>
        <p dir="ltr">
          <strong id="docs-internal-guid-8fc68abc-7fff-5087-72a6-959605de45a6">
            <br />
          </strong>
        </p>
        <p dir="ltr">
          Welcome to the Terms of Service (these “Terms”) for www.lameloball.io
          (the “Website”), operated by Playgrounds Labs LLC (“Company”, “we” or
          “us”). The Website and any content, tools, features and functionality
          offered on or through our Website are collectively referred to as the
          “Services”.
        </p>
        <p dir="ltr">
          These Terms govern your access to and use of the Services. Please read
          these Terms carefully, as they include important information about
          your legal rights. By accessing and/or using the Services, you are
          agreeing to these Terms. If you do not understand or agree to these
          Terms, please do not use the Services.
        </p>
        <p dir="ltr">
          For purposes of these Terms, “you” and “your” means you as the user of
          the Services. If you use the Services on behalf of a company or other
          entity then “you” includes you and that entity, and you represent and
          warrant that (a) you are an authorized representative of the entity
          with the authority to bind the entity to these Terms, and (b) you
          agree to these Terms on the entity's behalf.
        </p>
        <p dir="ltr">
          Section 11 contains an arbitration clause and class action waiver. By
          agreeing to these Terms, you agree (a) to resolve all disputes with us
          related to the Services through binding individual arbitration, which
          means that you waive any right to have those disputes decided by a
          judge or jury, and (b) to waive your right to participate in class
          actions, class arbitrations, or representative actions in connection
          with your use of the Services. You have the right to opt-out of
          arbitration as explained in Section 11.
        </p>
        <ol>
          <li dir="ltr">
            <h1 dir="ltr">Who May Use the Services</h1>
          </li>
          <p dir="ltr">
            You must be eighteen (18) years of age or older to use the Services.
            By using the Services, you represent and warrant that you meet these
            requirements.
          </p>
          <li dir="ltr">
            <h1 dir="ltr">The Services</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  The Company operates a blockchain-based platform called LaMelo
                  Ball Collectibles, and related technologies, applications,
                  services, platforms and systems that are available on the
                  Website. The Website allows you to purchase Non-Fungible
                  Tokens (“NFTs”) that are associated with rare memorabilia and
                  other physical products (“Products,” and such NFTs, “Product
                  NFTs”). Collectively, the Products and Product NFTs, are
                  referred to as the “Offerings”.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  The Product NFTs do not represent ownership shares of the
                  Product or an interest in any part thereof. However, a key
                  utility of the Product NFTs is that if you have a redeemable
                  Trait associated with such Product (the “NFT Purchase
                  Threshold”), the Website allows you to initiate a transaction
                  to redeem the physical Products as determined in the Trait.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  You are solely responsible for determining whether such Trait
                  is available within Product NFTs.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  All transactions initiated through our Website (whether for
                  NFTs or Products) are run by third-party electronic wallet
                  applications that you choose to use, such as Metamask. By
                  using our Website you agree that you are governed by the terms
                  or service and privacy policies of those wallet applications.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  The Product NFTs may be made available on the Website or on
                  other marketplaces (including third party secondary
                  marketplaces) for secondary sales by owners of such Product
                  NFTs. The smart contract for Product NFTs will result in a
                  percentage of each secondary sale of NFTs being paid to
                  Company as a transaction fee for such secondary sales. Company
                  will not be responsible or liable to you for the acts or
                  omissions of third party marketplaces where you may choose to
                  purchase or sell Product NFTs.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  We reserve the right, with or without prior notice and in our
                  sole and complete discretion, to (a) delist, discontinue,
                  modify, or limit the available quantity of any Offerings, and
                  (b) refuse to allow any user to purchase any Offering. All
                  fees and charges are payable in accordance with payment terms
                  in effect at the time the fee or the charge becomes payable.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  No Refunds. There are no refunds available for any purchases
                  on the Website.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">User Accounts</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Creating and Safeguarding your Account. To use certain of the
                  Services, you may need to create an account (“Account”). You
                  agree to provide us with accurate, complete and updated
                  information for your Account. You can access, edit and update
                  your Account via the account page of the Website. We are not
                  liable for any acts or omissions by you in connection with
                  your Account. Company has the right to block multiple accounts
                  of the same user. You agree that you will not create a new
                  account if Company has disabled or deleted your Account. To
                  create an Account or otherwise make a purchase of any Product
                  NFTs or Products, you will need a digital wallet, such as
                  MetaMask, which will allow you to engage in transactions on
                  the Website using compatible cryptocurrency. You represent and
                  warrant that you have the legal right to use the digital
                  wallet you link to the Website.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Location of Our Privacy Policy</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Privacy Policy. Our Privacy Policy describes how we handle the
                  information you provide to us when you use the Services.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Rights We Grant You</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  License Grant. Subject to your compliance with these Terms,
                  the Company hereby grants to you, a personal, worldwide,
                  royalty-free, non-assignable, non-sublicensable,
                  non-transferrable, and non-exclusive license to use the
                  software provided to you as part of the Services. This license
                  has the sole purpose of enabling you to use and enjoy the
                  benefit of the Services as provided by us, in the manner
                  permitted by these Terms and subject to the use restrictions
                  described below. Your access and use of the Services may be
                  interrupted from time to time for any of several reasons,
                  including, without limitation, the malfunction of equipment,
                  periodic updating, maintenance or repair of the Service or
                  other actions that Company, in its sole discretion, may elect
                  to take.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  NFT Ownership; Media. Because each Product NFT is an NFT on
                  the Ethereum blockchain, when you purchase a Product NFT in
                  accordance with these Terms, you own the underlying NFT
                  completely. This means that you have the right to swap your
                  Product NFT, sell it, or give it away. Ownership of the
                  Product NFT is mediated entirely by the Ethereum blockchain
                  and not by Company. However, owning a Product NFT does not
                  mean that you own the art, design and drawings (in any form or
                  media, including without limitation, video or photographs),
                  that may be associated with a Product NFT that you own
                  (“Associated Media”). The Associated Art remains owned by the
                  Company.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Associated Art License. Subject to your continued compliance
                  with these Terms, we grant you a worldwide, non-exclusive,
                  non-transferable, royalty-free license to use, copy, and
                  display the Associated Art for your Product NFTs, solely for
                  the following purposes: (a) for your own personal,
                  non-commercial use; (b) as part of a marketplace that permits
                  the purchase and sale of your Purchased Moments, provided that
                  the marketplace cryptographically verifies each Product NFT’s
                  owner’s rights to display the Art for their Product NFT to
                  ensure that only the actual owner can display the Art; or (c)
                  as part of a third party website or application that permits
                  the inclusion, involvement, or participation of your Purchased
                  Moment, provided that the website/application
                  cryptographically verifies each Product NFTs owner’s rights to
                  display the Associated Art for their Product NFT to ensure
                  that only the actual owner can display the Associated Art, and
                  provided that the Associated Art is no longer visible once the
                  owner of the Product NFT leaves the website/application. You
                  agree that you may not, nor permit any third party to do or
                  attempt to do any of the foregoing without our (or, as
                  applicable, our licensors’) express prior written consent in
                  each case: (a) modify the Associated Art for your Product NFT
                  in any way; (b) use the Associated Art for your Product NFT to
                  promote or sell any third party product or service; (c) use
                  the Associated Art for your Product NFT in movies, videos, or
                  any other forms of media, except to the limited extent that
                  such use is expressly permitted in these Terms or solely for
                  your own personal, non-commercial use; (d) manufacture, sell,
                  distribute for commercial gain (including, without limitation,
                  giving away in the hopes of eventual commercial gain), or
                  otherwise commercialize merchandise that includes, contains,
                  or consists of the Associated Art for your Product NFT; (e)
                  attempt to trademark, copyright, or otherwise acquire
                  additional intellectual property rights in or to the
                  Associated Art for your Product NFT; or (f) otherwise utilize
                  the Associated Art for your Product NFT for your or any third
                  party’s commercial benefit.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Prohibited activities</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Restrictions On Your Use of the Services. You may not do any
                  of the following, unless applicable laws or regulations
                  prohibit these restrictions or you have our written permission
                  to do so:
                </h2>
                <ol>
                  <li dir="ltr">
                    <h3 dir="ltr">create or list counterfeit items;</h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      download, modify, copy, distribute, transmit, display,
                      perform, reproduce, duplicate, publish, license, create
                      derivative works from, or offer for sale any information
                      contained on, or obtained from or through, the Services;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      duplicate, decompile, reverse engineer, disassemble or
                      decode the Services (including any underlying idea or
                      algorithm), or attempt to do any of the same;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      use, reproduce or remove any copyright, trademark, service
                      mark, trade name, slogan, logo, image, or other
                      proprietary notation displayed on or through the Services;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      use automation software (bots), hacks, modifications
                      (mods) or any other unauthorized third-party software
                      designed to modify the Services;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      exploit the Services for any commercial purpose, including
                      without limitation communicating or facilitating any
                      commercial advertisement or solicitation;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      access or use the Services in any manner that could
                      disable, overburden, damage, disrupt or impair the
                      Services or interfere with any other party's access to or
                      use of the Services or use any device, software or routine
                      that causes the same;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      attempt to gain unauthorized access to, interfere with,
                      damage or disrupt the Services, accounts registered to
                      other users, or the computer systems or networks connected
                      to the Services;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      circumvent, remove, alter, deactivate, degrade or thwart
                      any technological measure or content protections of the
                      Services;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      use any robot, spider, crawlers or other automatic device,
                      process, software or queries that intercepts, “mines,”
                      scrapes or otherwise accesses the Services to monitor,
                      extract, copy or collect information or data from or
                      through the Services, or engage in any manual process to
                      do the same;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      introduce any viruses, trojan horses, worms, logic bombs
                      or other materials that are malicious or technologically
                      harmful into our systems;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      engage in wash trading or other deceptive or manipulative
                      trading activities;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      Use the Service to carry out any financial activities
                      subject to registration or licensing, including but not
                      limited to creating, listing, or buying securities,
                      commodities, options, real estate, or debt instruments;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      use the Services for illegal, harassing, unethical, or
                      disruptive purposes;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      violate any applicable law or regulation in connection
                      with your access to or use of the Services; or
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      access or use the Services in any way not expressly
                      permitted by these Terms.
                    </h3>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Ownership and Content</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Ownership of the Services. The Services, including their “look
                  and feel” (e.g., text, graphics, images, logos), proprietary
                  content, information and other materials, are protected under
                  copyright, trademark and other intellectual property laws. You
                  agree that the Company and/or its licensors own all right,
                  title and interest in and to the Services (including any and
                  all intellectual property rights therein) and you agree not to
                  take any action(s) inconsistent with such ownership interests.
                  We and our licensors reserve all rights in connection with the
                  Services and its content (other than Your Content), including,
                  without limitation, the exclusive right to create derivative
                  works.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Ownership of Trademarks. The Company’s name, and all related
                  names, logos, product and service names, designs and slogans
                  are trademarks of the Company or its affiliates or licensors.
                  Other names, logos, product and service names, designs and
                  slogans that appear on the Services are the property of their
                  respective owners, who may or may not be affiliated with,
                  connected to, or sponsored by us.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Ownership of Feedback. We welcome feedback, comments and
                  suggestions for improvements to the Services (“Feedback”). You
                  acknowledge and expressly agree that any contribution of
                  Feedback does not and will not give or grant you any right,
                  title or interest in the Services or in any such Feedback. All
                  Feedback becomes the sole and exclusive property of the
                  Company, and the Company may use and disclose Feedback in any
                  manner and for any purpose whatsoever without further notice
                  or compensation to you and without retention by you of any
                  proprietary or other right or claim. You hereby assign to the
                  Company any and all right, title and interest (including, but
                  not limited to, any patent, copyright, trade secret,
                  trademark, show-how, know-how, moral rights and any and all
                  other intellectual property right) that you may have in and to
                  any and all Feedback.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">Notice of Infringement – DMCA Policy</h2>
                <p dir="ltr">
                  If you believe that any text, graphics, photos, audio, videos
                  or other materials or works uploaded, downloaded or appearing
                  on the Services have been copied in a way that constitutes
                  copyright infringement, you may submit a notification to our
                  copyright agent in accordance with 17 USC 512(c) of the
                  Digital Millennium Copyright Act (the “DMCA”), by providing
                  the following information in writing:
                </p>
                <ol>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      identification of the copyrighted work that is claimed to
                      be infringed;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      identification of the allegedly infringing material that
                      is requested to be removed, including a description of
                      where it is located on the Service;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      information for our copyright agent to contact you, such
                      as an address, telephone number and e-mail address;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      a statement that you have a good faith belief that the
                      identified, allegedly infringing use is not authorized by
                      the copyright owners, its agent or the law;
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      a statement that the information above is accurate, and
                      under penalty of perjury, that you are the copyright owner
                      or the authorized person to act on behalf of the copyright
                      owner; and
                    </h3>
                  </li>
                  <li dir="ltr">
                    <h3 dir="ltr">
                      the physical or electronic signature of a person
                      authorized to act on behalf of the owner of the copyright
                      or of an exclusive right that is allegedly infringed.
                    </h3>
                  </li>
                </ol>
                <p dir="ltr">
                  Notices of copyright infringement claims should be sent to:
                  Playgrounds Labs LLC, Attn: COPYRIGHT AGENT by e-mail to
                  team@playgroundstudios.io. It is our policy, in appropriate
                  circumstances and at our discretion, to disable or terminate
                  the accounts of users who repeatedly infringe copyrights or
                  intellectual property rights of others.
                </p>
                <p dir="ltr">
                  A user of the Services who has uploaded or posted materials
                  identified as infringing as described above may supply a
                  counter-notification pursuant to sections 512(g)(2) and (3) of
                  the DMCA. When we receive a counter-notification, we may
                  reinstate the posts or material in question, in our sole
                  discretion. To file a counter-notification with us, you must
                  provide a written communication (by fax or regular mail or by
                  email) that sets forth all of the items required by sections
                  512(g)(2) and (3) of the DMCA. Please note that you will be
                  liable for damages if you materially misrepresent that content
                  or an activity is not infringing the copyrights of others.
                </p>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Third Party Services and Materials</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Use of Third Party Materials in the Services. Certain Services
                  may display, include or make available content, data,
                  information, applications or materials from third parties
                  (“Third Party Materials”) or provide links to certain third
                  party websites. By using the Services, you acknowledge and
                  agree that the Company is not responsible for examining or
                  evaluating the content, accuracy, completeness, availability,
                  timeliness, validity, copyright compliance, legality, decency,
                  quality or any other aspect of such Third Party Materials or
                  websites. We do not warrant or endorse and do not assume and
                  will not have any liability or responsibility to you or any
                  other person for any third-party services, Third Party
                  Materials or third-party websites, or for any other materials,
                  products, or services of third parties. Third Party Materials
                  and links to other websites are provided solely as a
                  convenience to you.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Third Party Names, Designs or Logos. Company does not claim
                  any ownership in any third party names, designs logos or other
                  intellectual property that may be displayed in a photograph of
                  a Product or elsewhere on the Website. Company is only
                  displaying an image of a Product and a description thereof so
                  that you may understand and evaluate what you are eligible to
                  purchase if you seek to purchase a Product in accordance with
                  Section 2 of these Terms. We make no claim that any person,
                  team, league or other entity that is referenced or displayed
                  in any Product has endorsed or authorized any such Product,
                  even if their name, image or logo is visible within a picture
                  of the Product or the description thereof.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">
              Disclaimers, Limitations of Liability and Indemnification
            </h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Disclaimers. Your access to and use of the Services are at
                  your own risk. You understand and agree that the Services are
                  provided to you on an “AS IS” and “AS AVAILABLE” basis.
                  Without limiting the foregoing, to the maximum extent
                  permitted under applicable law, the Company, its parents,
                  affiliates, related companies, officers, directors, employees,
                  agents, representatives, partners and licensors (the “the
                  Company Entities”) DISCLAIM ALL WARRANTIES AND CONDITIONS,
                  WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE OR NON-INFRINGEMENT. The Company Entities
                  make no warranty or representation and disclaim all
                  responsibility and liability for: (a) transactions between you
                  and any Seller; (b) the authenticity, safety, or legality of
                  the Products offered or sold on the Website; (c) the
                  completeness, accuracy, availability, timeliness, security or
                  reliability of the Services; (d) any harm to your computer
                  system, loss of data, or other harm that results from your
                  access to or use of the Services; (e) the operation or
                  compatibility with any other application or any particular
                  system or device; (f) whether the Services will meet your
                  requirements or be available on an uninterrupted, secure or
                  error-free basis; and (g) the deletion of, or the failure to
                  store or transmit Your Content and other communications
                  maintained by the Services. No advice or information, whether
                  oral or written, obtained from the Company Entities or through
                  the Services, will create any warranty or representation not
                  expressly made herein.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS
                  RELATED TO YOUR USE OF CRYPTO ASSETS, INCLUDING BUT NOT
                  LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A)
                  INCORRECTLY CONSTRUCTED TRANSACTIONS OR MISTYPED ADDRESSES;
                  (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES;
                  (D) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES. WE MAKE NO
                  GUARANTEES OR PROMISES WITH RESPECT TO ANY OF THE SMART
                  CONTRACTS RELATED TO PRODUCT NFTS.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Limitations of Liability. TO THE EXTENT NOT PROHIBITED BY LAW,
                  YOU AGREE THAT IN NO EVENT WILL THE COMPANY ENTITIES BE LIABLE
                  (A) FOR ANY INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL,
                  CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED
                  TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
                  DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR
                  LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO
                  USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF
                  LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN
                  ANY WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS AND
                  WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING
                  NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY
                  OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR
                  ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE
                  DELIVERY, USE OR PERFORMANCE OF THE SERVICES. SOME
                  JURISDICTIONS (SUCH AS THE STATE OF NEW JERSEY) DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
                  DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO
                  YOU. THE COMPANY IS NOT LIABLE FOR (I) CONTENT POSTED ON THE
                  SERVICES OR (II) ANY SELLER’S FAILURE TO FULFIL OBLIGATIONS
                  PURSUANT TO CONTRACTS TO PURCHASE MADE VIA THE SERVICES, SUCH
                  AS A SELLER’S FAILURE TO PROPERLY AUTHENTICATE A PRODUCT OR
                  SHIP A PRODUCT, IF THE SELLER HAS PHYSICAL POSSESSION OF THE
                  PRODUCT INSTEAD OF THE COMPANY. THE COMPANY ENTITIES’ TOTAL
                  LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT
                  EXCEED THE AMOUNT OF ONE HUNDRED DOLLARS ($100.00), OR THE
                  AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX
                  (6) MONTHS FOR THE SERVICES (OR OFFERINGS PURCHASED ON THE
                  SERVICES) GIVING RISE TO THE CLAIM. THE FOREGOING LIMITATIONS
                  WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS
                  ESSENTIAL PURPOSE.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Indemnification. By entering into these Terms and accessing or
                  using the Services, you agree that you shall defend, indemnify
                  and hold the Company Entities harmless from and against any
                  and all claims, costs, damages, losses, liabilities and
                  expenses (including attorneys’ fees and costs) incurred by the
                  Company Entities arising out of or in connection with: (a)
                  your violation or breach of any term of these Terms or any
                  applicable law or regulation; (b) your violation of any rights
                  of any third party; (c) your access to or use of the Services;
                  (d) Your Content; or (e) your negligence or wilful misconduct.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">ASSUMPTION OF RISK</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  The prices of collectible blockchain assets are volatile and
                  subjective and NFTs have no inherent or intrinsic value, and
                  you understand that the value of the Product NFTs may
                  significantly decline, including but not limited to as the
                  result of a decline in the desirability of the associated
                  Product or the person, team, league or sport related thereto.
                  You are solely responsible for determining what, if any, taxes
                  apply to your transactions on the Website.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Our Services do not store, send, or receive NFTs. This is
                  because NFTs exist only by virtue of the ownership record
                  maintained on the Services’ supporting blockchain.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  There are risks associated with using an Internet-based
                  currency, including, but not limited to, the risk of hardware,
                  software and Internet connections, the risk of malicious
                  software introduction, and the risk that third parties may
                  obtain unauthorized access to information stored within your
                  electronic wallet. You accept and acknowledge that we will not
                  be responsible for any communication failures, disruptions,
                  errors, distortions or delays you may experience when using a
                  blockchain in connection with the Services, however caused.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  The regulatory regime governing blockchain technologies,
                  cryptocurrencies and tokens is uncertain, and new regulations
                  or policies may materially adversely affect the development of
                  Services, and therefore the potential utility of your Product
                  NFTs.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Upgrades to the Ethereum Network, a hard fork in the Ethereum
                  Network, or a change in how transactions are confirmed on the
                  Ethereum Network may have unintended, adverse effects on all
                  blockchains using the Ethereum Network’s various NFT
                  standards.
                </h2>
              </li>
            </ol>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">ARBITRATION AND CLASS ACTION WAIVER</h1>
            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Informal Process First. You agree that in the event of any
                  dispute between you and the Company Entities, you will first
                  contact the Company and make a good faith sustained effort to
                  resolve the dispute before resorting to more formal means of
                  resolution, including without limitation, any court action.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Arbitration Agreement and Class Action Waiver. After the
                  informal dispute resolution process, any remaining dispute,
                  controversy, or claim (collectively, “Claim”) relating in any
                  way to your use of the Company’s services and/or products,
                  including the Services, will be resolved by arbitration,
                  including threshold questions of arbitrability of the Claim.
                  You and the Company agree that any Claim will be settled by
                  final and binding arbitration, using the English language,
                  administered by JAMS under its Comprehensive Arbitration Rules
                  and Procedures (the “JAMS Rules”) then in effect (those rules
                  are deemed to be incorporated by reference into this section,
                  and as of the date of these Terms). Arbitration will be
                  handled by a sole arbitrator in accordance with the JAMS
                  Rules. Judgment on the arbitration award may be entered in any
                  court that has jurisdiction. Any arbitration under these Terms
                  will take place on an individual basis – class arbitrations
                  and class actions are not permitted. You understand that by
                  agreeing to these Terms, you and the Company are each waiving
                  the right to trial by jury or to participate in a class action
                  or class arbitration. Notwithstanding the foregoing, you and
                  the Company will have the right to bring an action in a court
                  of proper jurisdiction for injunctive or other equitable or
                  conservatory relief, pending a final decision by the
                  arbitrator. You may instead assert your claim in “small
                  claims” court, but only if your claim qualifies, your claim
                  remains in such court and your claim remains on an individual,
                  non-representative and non-class basis.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Costs of Arbitration. Payment for any and all reasonable JAMS
                  filing, administrative and arbitrator fees will be in
                  accordance with the JAMS Rules. If the value of your claim
                  does not exceed ten thousand dollars ($10,000), the Company
                  will pay for the reasonable filing, administrative and
                  arbitrator fees associated with the arbitration, unless the
                  arbitrator finds that either the substance of your claim or
                  the relief sought was frivolous or brought for an improper
                  purpose.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Opt-Out. You have the right to opt-out and not be bound by the
                  arbitration provisions set forth in these Terms by sending
                  written notice of your decision to opt-out to
                  <a href="mailto:team@playgroundstudios.io">
                    team@playgroundstudios.io
                  </a>
                  . The notice must be sent to the Company within thirty (30)
                  days of your registering to use the Services or agreeing to
                  these Terms, otherwise you shall be bound to arbitrate
                  disputes in accordance with these Terms. If you opt-out of
                  these arbitration provisions, the Company also will not be
                  bound by them.
                </h2>
              </li>
            </ol>
          </li>
          <li dir="ltr">
            <h1 dir="ltr">Additional Provisions</h1>

            <ol>
              <li dir="ltr">
                <h2 dir="ltr">
                  Updating These Terms. We may modify these Terms from time to
                  time in which case we will update the “Last Revised” date at
                  the top of these Terms. If we make changes that are material,
                  we will use reasonable efforts to attempt to notify you, such
                  as by e-mail and/or by placing a prominent notice on the first
                  page of the Website. However, it is your sole responsibility
                  to review these Terms from time to time to view any such
                  changes. The updated Terms will be effective as of the time of
                  posting, or such later date as may be specified in the updated
                  Terms. Your continued access or use of the Services after the
                  modifications have become effective will be deemed your
                  acceptance of the modified Terms.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Termination of License and Your Account. If you breach any of
                  the provisions of these Terms, all licenses granted by the
                  Company will terminate automatically. Additionally, the
                  Company may suspend, disable, or delete your Account and/or
                  the Services (or any part of the foregoing) with or without
                  notice, for any or no reason. If the Company deletes your
                  Account for any suspected breach of these Terms by you, you
                  are prohibited from re-registering for the Services under a
                  different name. In the event of Account deletion for any
                  reason, the Company may, but is not obligated to, delete any
                  of Your Content. the Company shall not be responsible for the
                  failure to delete or deletion of Your Content. All sections
                  which by their nature should survive the termination of these
                  Terms shall continue in full force and effect subsequent to
                  and notwithstanding any termination of this Agreement by the
                  Company or you. Termination will not limit any of the
                  Company’s other rights or remedies at law or in equity.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Injunctive Relief. You agree that a breach of these Terms will
                  cause irreparable injury to the Company for which monetary
                  damages would not be an adequate remedy and the Company shall
                  be entitled to equitable relief in addition to any remedies it
                  may have hereunder or at law without a bond, other security or
                  proof of damages.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  California Residents. If you are a California resident, in
                  accordance with Cal. Civ. Code § 1789.3, you may report
                  complaints to the Complaint Assistance Unit of the Division of
                  Consumer Services of the California Department of Consumer
                  Affairs by contacting them in writing at 1625 North Market
                  Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at
                  (800) 952-5210.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Export Laws. You agree that you will not export or re-export,
                  directly or indirectly, the Services and/or other information
                  or materials provided by the Company hereunder, to any country
                  for which the United States or any other relevant jurisdiction
                  requires any export license or other governmental approval at
                  the time of export without first obtaining such license or
                  approval. In particular, but without limitation, the Services
                  may not be exported or re-exported (a) into any U.S. embargoed
                  countries or any country that has been designated by the U.S.
                  Government as a “terrorist supporting” country, or (b) to
                  anyone listed on any U.S. Government list of prohibited or
                  restricted parties, including the U.S. Treasury Department's
                  list of Specially Designated Nationals or the U.S. Department
                  of Commerce Denied Person’s List or Entity List. By using the
                  Services, you represent and warrant that you are not located
                  in any such country or on any such list. You are responsible
                  for and hereby agree to comply at your sole expense with all
                  applicable United States export laws and regulations.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  Miscellaneous. If any provision of these Terms shall be
                  unlawful, void or for any reason unenforceable, then that
                  provision shall be deemed severable from these Terms and shall
                  not affect the validity and enforceability of any remaining
                  provisions. These Terms and the licenses granted hereunder may
                  be assigned by the Company but may not be assigned by you
                  without the prior express written consent of the Company. No
                  waiver by either party of any breach or default hereunder
                  shall be deemed to be a waiver of any preceding or subsequent
                  breach or default. The section headings used herein are for
                  reference only and shall not be read to have any legal effect.
                  The Services are operated by us in the United States. Those
                  who choose to access the Services from locations outside the
                  United States do so at their own initiative and are
                  responsible for compliance with applicable local laws. These
                  Terms are governed by the laws of the State of California,
                  without regard to conflict of laws rules, and the proper venue
                  for any disputes arising out of or relating to any of the same
                  will be the arbitration venue set forth in Section 11, or if
                  arbitration does not apply, then the state and federal courts
                  located in Los Angeles County. You and the Company agree that
                  the United Nations Convention on Contracts for the
                  International Sale of Goods will not apply to the
                  interpretation or construction of these Terms.
                </h2>
              </li>
              <li dir="ltr">
                <h2 dir="ltr">
                  How to Contact Us. You may contact us regarding the Services
                  or these Terms by e-mail at{" "}
                  <a href="mailto:team@playgroundstudios.io">
                    team@playgroundstudios.io
                  </a>
                  .
                </h2>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};
export default Tnc;
