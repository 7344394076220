import React, { useEffect, useRef, useState, useContext, memo } from 'react';
import { Link } from 'react-router-dom';

import useContainerDimensions from './useContainerDimensions';
import './EmbeddedCard.css';
import config from '../../config';
import _points from '../../data/points';
import _steals from '../../data/steals';
import _assists from '../../data/assists';
import { getTokenMeta } from '../Utils';
import useOnScreen from '../../hooks/useOnScreen';

import axios from 'axios';

const forgeTrait = {
   "id": 99,
   "type": "",
   "name": "Forge",
   "icon": "forge.png",
   "description": `Owners of a full collection from Drop 001 (Gold, Silver, Blue, Red) will be able to mint a Drop 002 NFT using The Forge on lameloball.io.
   Forge Starts:28 FEB, 5 pm PST - Ends: 02 March, 5 pm PST`,
   "properties": {
      "card_types": "",
      "category": "forge",
      "max_issuance": "",
      "burnt_upon_use": true,
      "max_usages": 1,
      "effect_of_transferring": "-",
      "limit": "-",
      "period_of_validity": "-",
      "community_trait": "-"
   }
}

const cardData = [
   { name: 'gold evolve', statLabel: 'stats', class: 'gold-evolve' },
   { name: 'silver moon', statLabel: 'assists', class: 'silver-moon' },
   { name: 'blue neptune', statLabel: 'steals', class: 'blue-neptune' },
   { name: 'red mars', statLabel: 'points', class: 'red-mars' }
]

const PointTable = memo(({ data }) => {

   return (
      <>
         <div className="st-head">
            <span className=" px-1">G</span>
            <span className=" px-1">DATE</span>
            <span className=" px-1">OPP</span>
            <span className=" px-1">FG</span>
            <span className=" px-1">3PT</span>
            <span className=" px-1">2PT</span>
            <span className=" px-1">FT</span>
            <span className=" px-1">PTS</span>
         </div>


         {data.map((row, i) => {
            return (
               <div className=" " key={'pt' + i}>
                  <span className=" px-1">{i + 1}</span>
                  <span className=" px-1">{row.date}</span>
                  <span className=" px-1">{row.opponent}</span>
                  <span className=" px-1">{row.fg}</span>
                  <span className=" px-1">{row.pt3}</span>
                  <span className=" px-1">{row.pt2}</span>
                  <span className=" px-1">{row.ft}</span>
                  <span className=" px-1">{row.pts}</span>
               </div>
            )
         })}
      </>
   );
})

const StealsTable = memo(({ data }) => {
   return (
      <>
         <div className="st-head">
            <span className=" px-1">GAME</span>
            <span className=" px-1">DATE</span>
            <span className=" px-1">OPP</span>
            <span className=" px-1">STEALS</span>
         </div>


         {data.map((row, i) => {
            return (
               <div className=" " key={'st' + i}>
                  <span className=" px-1">{i + 1}</span>
                  <span className=" px-1">{row.date}</span>
                  <span className=" px-1">{row.opponent}</span>
                  <span className=" px-1">{row.steals}</span>
               </div>
            )
         })}
      </>
   );
})

const AssistsTable = memo(({ data }) => {
   return (
      <>
         <div className="st-head">
            <span className=" px-1">GAME</span>
            <span className=" px-1">DATE</span>
            <span className=" px-1">OPP</span>
            <span className=" px-1">ASSISTS</span>
         </div>


         {data.map((row, i) => {
            return (
               <div className=" " key={'as' + i}>
                  <span className=" px-1">{i + 1}</span>
                  <span className=" px-1">{row.date}</span>
                  <span className=" px-1">{row.opponent}</span>
                  <span className=" px-1">{row.assists}</span>
               </div>
            )
         })}
      </>
   );
})

const EmbeddedCard = (props) => {
   const containerRef = useRef()
   const isVisible = useOnScreen(containerRef, props.onScreenDelay);
   const { width, height } = useContainerDimensions(containerRef, isVisible);

   const [containerSize, setContainerSize] = useState('c-medium');

   const [statsVisible, setStatsVisible] = useState(false);
   const [traitsVisible, setTraitsVisible] = useState(false);

   const [tokenId, setTokenId] = useState(null);
   const [cardType, setCardType] = useState(0);
   const [subType, setSubType] = useState(0);
   const [meta, setMeta] = useState(null);
   const [traits, setTraits] = useState([]);
   const [selectedTraitIdx, setSelectedTraitIdx] = useState(null);
   const [selectedTraitId, setSelectedTraitId] = useState(null);
   const [flipped, setFlipped] = useState(false);

   const [points, setPoints] = useState(_points);
   const [steals, setSteals] = useState(_steals);
   const [assists, setAssists] = useState(_assists)

   useEffect(() => {
      setupStats()
   }, [])

   useEffect(() => {
      if (width) {
         let cSize = '';
         if (width > 500) {
            cSize = 'c-xlarge';
         } else if (width > 400) {
            cSize = 'c-large';
         } else if (width > 300) {
            cSize = 'c-medium';
         } else if (width > 240) {
            cSize = 'c-small';
         } else {
            cSize = 'c-xsmall'
         }
         setContainerSize(cSize);
         // console.log(containerSize)
      }
   }, [width])

   useEffect(() => {
      const setupToken = async () => {

         if (!props.disableTraits) {
            const url = config.META_SERVER + '/' + props.tokenId % 100 + '/' + props.tokenId + '.json'

            const m = await getTokenMeta(url);


            var traits = [];
            if (m && m.traits) {
               traits = m.traits;
            }
            if (m && m.forge) {
               traits.push(forgeTrait)
            }
            setTraits(traits)

            setMeta(m);
         }

         setTokenId(Number.parseInt(props.tokenId));
         setCardType(Number.parseInt(props.cardType));
         setSubType(0);
      }


      if (props.tokenId && props.cardType !== null) {
         setupToken();
      }
   }, [props.tokenId, props.cardType])


   const setupStats = async () => {
      if (!props.disableStats && !props.stats) {
         await axios({
            method: "get",
            url: `${config.META_SERVER}/stats`,
         })
            .then((res) => {
               if (res.data) {
                  setPoints(res.data.points)
                  setAssists(res.data.assists)
                  setSteals(res.data.steals)
               }
            })
            .catch(e => {
               console.error(e)
            });
      } else if (props.stats) {
         setPoints(props.stats.points)
         setAssists(props.stats.assists)
         setSteals(props.stats.steals)
      }
   }


   const closeStatsOrTraits = () => {
      if (flipped) {
         setFlipped(false);
         return;
      }

      if (statsVisible || traitsVisible) {
         setStatsVisible(false);
         setTraitsVisible(false);
      }
   }

   const showTrait = (idx, id) => {
      setSelectedTraitIdx(idx);
      setSelectedTraitId(id);
      setFlipped(true);
   }


   return (
      <>
         <div ref={containerRef} className={`nft-token embed ${!isVisible && "hidden"} ${tokenId === null ? 'hidden' : ''} ${containerSize} ${cardData[cardType].class} ${statsVisible ? 'stats-visible' : traitsVisible ? 'traits-visible' : ''}`}>
            <div className="nft-header d-flex align-items-center">
               {!traitsVisible ?
                  <h5 className="mb-0 w-50 pl-2">{cardData[cardType].name}</h5>
                  :
                  <h5 className="mb-0 w-50 pl-2">traits</h5>
               }
               {(!statsVisible && !traitsVisible) && <h5 className={`mb-0 w-25 text-center stats-cta ${props.disableStats && "d-none"}`} onClick={() => setStatsVisible(true)}>{cardData[cardType].statLabel}</h5>}
               {(traitsVisible || statsVisible) ?
                  <h5 className="mb-0 w-25 text-right pr-2 close-btn ml-auto" onClick={() => closeStatsOrTraits()}>X</h5>
                  :
                  <h5 className={`mb-0 w-25 text-right pr-2 traits-cta ml-auto ${props.disableTraits && "d-none"}`} onClick={() => setTraitsVisible(true)}>{traits.length}</h5>
               }
            </div>

            <div className={`video-container ${props.disableVideo && "d-none"} ${!isVisible && "d-none"}`}>
               <video
                  loop
                  muted
                  autoPlay
                  playsInline
                  src={config.NFT_ANIMS[cardType]}
               />
            </div>

            <div className={`img-container object-fit-contain ${!props.disableVideo && "d-none"}`}>
               <img src={`${config.NFT_IMGS[cardType]}`}></img>
            </div>

            <div className={`stats ${props.disableStats && "d-none"}`}>
               {cardType > 0 ?
                  <h1 className="text-center">{cardData[cardType].statLabel}</h1>
                  :
                  <div className="gold-tabs d-flex justify-content-between">
                     <span>
                        <input type="radio" name="stat-tab" id={`points_${tokenId}`} value={subType} checked={subType === 0} onChange={() => { setSubType(0) }} />
                        <label htmlFor={`points_${tokenId}`} >points</label>
                     </span>
                     <span>
                        <input type="radio" name="stat-tab" id={`steals_${tokenId}`} value={subType} checked={subType === 1} onChange={() => { setSubType(1) }} />
                        <label htmlFor={`steals_${tokenId}`}>steals</label>
                     </span>
                     <span>
                        <input type="radio" name="stat-tab" id={`assists_${tokenId}`} value={subType} checked={subType === 2} onChange={() => { setSubType(2) }} />
                        <label htmlFor={`assists_${tokenId}`}>assists</label>
                     </span>
                  </div>
               }

               <div className="st-container">
                  <div className=" text-center w-100 stats-table">

                     {(cardType === 3 || (cardType === 0 && subType === 0)) &&
                        <PointTable data={points} />
                     }

                     {(cardType === 2 || (cardType === 0 && subType === 1)) &&
                        <StealsTable data={steals} />
                     }

                     {(cardType === 1 || (cardType === 0 && subType === 2)) &&
                        <AssistsTable data={assists} />
                     }

                  </div>
               </div>

               <div className="stat-footer d-flex align-items-center justify-content-between">
                  <h2 className="m-0">Lamelo Ball</h2>
                  <div className="token-id">{tokenId}</div>
               </div>
            </div>

            <div className={`traits ${props.disableTraits && "d-none"}`}>

               {(traits && traits.length === 0) ?
                  <>
                     <h1 className="text-center">You don't have any traits yet.</h1>
                  </>
                  :
                  <div className={`faces ${flipped && 'flipped'}`}>

                     <div className="trait-container row mx-5 align-content-center text-center">
                        {
                           traits.map((t, i) => {
                              return (
                                 <div key={'ti' + i} className="trait-image col-4 my-2 p-1 mx-auto" onClick={() => { showTrait(i, t.id) }}>
                                    <img src={`${process.env.PUBLIC_URL}/traits/${t.icon}`} alt={t.name} />
                                 </div>
                              )
                           })
                        }
                     </div>

                     <div className="trait-details p-2 mt-3">
                        {selectedTraitIdx !== null &&
                           <>
                              <div className={`row align-items-end m-0 ${traits[selectedTraitIdx].properties.category !== 'redeemable' ? 'mt-5 pt-5' : 'pt-4'}`}>
                                 <div className="col-2 text-right trait-detail-image p-0">
                                    <img src={`${process.env.PUBLIC_URL}/traits/${traits[selectedTraitIdx].icon}`} alt={traits[selectedTraitIdx].name} />
                                 </div>
                                 <div className="col-10">
                                    <h3 className="trait-title">{traits[selectedTraitIdx].name}</h3>

                                 </div>
                              </div>

                              <div className="row mt-2 px-md-0 px-sm-2 mx-0">
                                 <div className="col-md-10 mx-auto border-bottom my-3">
                                 </div>
                              </div>

                              <div className="row mt-2 px-md-0 px-sm-2 mx-0">
                                 <div className="col-md-10 mx-auto">
                                    <p className="trait-description">{traits[selectedTraitIdx].description}</p>

                                    {traits[selectedTraitIdx].properties.category === 'redeemable' &&
                                       <>
                                          <p className="trait-description">The redeemable item for this trait is:</p>
                                          <h5 className="redeemable-name">1 x {traits[selectedTraitIdx].name}</h5>

                                          <Link className="btn btn-outline round redeem-link" to={`/redeem/${props.tokenId}/${selectedTraitId}`} >Redeem</Link>

                                       </>
                                    }
                                 </div>
                              </div>
                           </>
                        }
                     </div>
                  </div>

               }



               <div className="stat-footer d-flex align-items-center justify-content-between">
                  <h2 className="m-0">Lamelo Ball</h2>
                  <div className="token-id">{tokenId}</div>
               </div>
            </div>


            <div className={`stat-footer id-footer align-items-center justify-content-end ${!props.showFrontId ? "d-none" : "d-flex"}`} >
               <div className="token-id">{tokenId}</div>
            </div>
         </div>
      </>
   );

}

export default EmbeddedCard;