import { createRipple } from "./RippleEffect";
import "./RippleButton.css";

const RippleButton = (props) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        createRipple(e);
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      className={`ripple-btn ${props.className || ""}`}
      style={{
        ...props.style,
      }}
    >
      {props.children}
    </button>
  );
};

export default RippleButton;
