import config from './config';
import Home from './components/Home/Home'
import BuyPage from './components/Home/BuyNft'
import FaqPage from './components/FAQ/FaqPage';
import WalletPage from './components/Wallet/WalletPage';
import TheDrop from './components/TheDrop/TheDrop';
import Raffle from './components/TheDrop/Raffle';
import EnterTheDrop from './components/TheDrop/EnterTheDrop';
import Card from './components/Card/Card';
import Card2 from './components/Card2/Card2';
import Forge from './components/Forge/Forge';
import Redeem from './components/Redemption/Redeem';
import Tnc from './components/Tnc/Tnc';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

let routes = [
     {
          'path': '/',
          'component': Home,
          'exact': true
     },
     {
          'path': '/buy',
          'component': BuyPage,
          'exact': true
     },
     {
          'path': '/faq',
          'component': FaqPage,
          'exact': true
     },
     {
          'path': '/wallet',
          'component': WalletPage,
          'exact': true
     },
     {
          'path': '/the-drop',
          'component': TheDrop,
          'exact': true
     },
     {
          'path': '/the-drop/enter',
          'component': EnterTheDrop,
          'exact': true
     },
     {
          'path': '/card/:tokenId',
          'component': Card,
          'exact': true
     },
     {
          'path': '/card2/:tokenId',
          'component': Card2,
          'exact': true
     },
     {
          'path': '/redeem/:tokenId/:traitId',
          'component': Redeem,
          'exact': true
     },
     {
          'path': '/tnc',
          'component': Tnc,
          'exact': true
     },
     {
          'path': '/privacy-policy',
          'component': PrivacyPolicy,
          'exact': true
     }

];



export default routes;

