import React, { useContext, useEffect, useState, useRef } from 'react';
import Navigation from '../Navigation/Navigation';
import './WalletPage.css';
import Web3Ctx from '../Context/Web3Ctx';
import { getContract } from '../Utils/GetContract';
import { SpinnerDotted } from 'spinners-react';
import { getTokenIds, zoomFetchTokenUris } from "../../utils/zoom";
import bgImage from '../../assets/images/drop_bg.jpg';
import EmbeddedCard from '../common/EmbeddedCard';
import EmbeddedCard2 from '../common/EmbeddedCard2';
import config from '../../config';

import { useLocation } from 'react-router-dom';
import { toast } from 'react-toast';
import axios from 'axios';


const WalletPage = (props) => {

    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);

    const scrollRef = useRef(null)
    const [ec, setEc] = useState(null);
    const [lamelo2Contract, setLamelo2Contract] = useState(null);
    const [zoomContract, setZoomContract] = useState(null);

    const [cards, setCards] = useState([]);
    const [cards2, setCards2] = useState([]);
    const [stats, setStats] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [cardArtwork, setCardArtwork] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(0);

    const location = useLocation();

    useEffect(() => {
        fetchStats();
    }, [])

    useEffect(() => {
        if (location.state && location.state.dropId != null) {
            setSelectedFilter(location.state.dropId)
        }
    }, [location.state])

    useEffect(() => {
        console.log('WALLET');

        const initContract = async () => {
            // console.log('WALLET [ethersProvider] running', ethersProvider)
            const contract = await getContract('LaMelo', ethersProvider).catch(e => console.log('err:', e));
            const contract2 = await getContract('LameloSaleForge', ethersProvider).catch(e => console.log('err:', e));
            const zoomContract = await getContract('Zoom2', ethersProvider).catch(e => console.log('err:', e));
            setEc(contract);
            setLamelo2Contract(contract2)
            setZoomContract(zoomContract)
        }

        if (ethersProvider) {
            initContract();
        }
    }, [ethersProvider]);

    useEffect(() => {
        if (ec && lamelo2Contract && zoomContract && address) {
            setIsConnected(true);
            getTokens();
        } else {
            setIsConnected(false);
        }
    }, [ec, address, lamelo2Contract, zoomContract]);


    const getTokens = async () => {
        if (!ec) {
            toast.error('Contract not found');
            return;
        }
        setIsLoading(true);

        const numberOfTokens = await ec.balanceOf(address);

        if (numberOfTokens.toNumber() > 0) {
            const tokenIds = await getTokenIds(
                zoomContract,
                ec,
                address,
                numberOfTokens
            );
            setCards(tokenIds);
        } else {
            setCards([]);
        }

        const tokens2 = await zoomFetchTokenUris(lamelo2Contract, zoomContract, address)
        if (tokens2) {
            setCards2(tokens2);
        } else {
            setCards2([])
        }

        setIsLoading(false);

        scrollToCards();
    }

    const fetchStats = async () => {
        await axios({
            method: "get",
            url: `${config.META_SERVER}/stats`,
        })
            .then((res) => {
                if (res.data) {
                    setStats(res.data)
                }
            })
            .catch(e => {
                console.error(e)
            });
    }

    const showImage = (imgUrl) => {
        setCardArtwork(imgUrl);
    }

    const connect = async () => {
        if (onboard) {
            await onboard.walletSelect();
        }
    }

    const selectFilter = (i) => {
        setSelectedFilter(i)
    }

    const getCardType = (i) => {
        return cards[i] <= 500 ? 0 : cards[i] <= 1500 ? 1 : cards[i] <= 3500 ? 2 : 3
    }

    const scrollToCards = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }


    return (
        <div id="application" style={{ backgroundImage: `url(${bgImage})`, backgroundColor: '#1C2330' }}>
            <Navigation {...props} />
            <div className="container">
                <div className="row padd">
                    <div className="col-lg-12 mt-5">

                        <h2 className="wallet-title text-center text-uppercase">Welcome to your wallet.</h2>

                        <div className="row">
                            <div ref={scrollRef} className="col-md-8 mx-auto text-center mt-5 mb-3 lead-text">

                                <p>Just like a cryptocurrency wallet, it will allow you to receive and store your cards.</p>
                                <p >You can find your wallet address in the top right corner of this page.</p>
                            </div>
                        </div>


                        {isConnected && <ul className="filters dw list-group d-flex flex-row flex-wrap mb-5 justify-content-center">
                            {
                                ['DROP 01', 'DROP 02'].map((label, i) => {
                                    return (
                                        <li className={`wallet-filter-item d-flex text-sm-center px-4 py-2 mx-2 ${selectedFilter === i ? 'active' : ''}`} onClick={(e) => selectFilter(i)} key={'lbl' + i}>
                                            {label}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        }

                        {isConnected ?
                            <>

                                {isLoading ?
                                    <div className="col-lg-12 mt-5 mb-5 text-center">
                                        <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                    </div>

                                    :
                                    <>
                                        <div className={`row ${selectedFilter != 0 && "d-none"}`}>
                                            {cards.map((key, i) => {
                                                return (
                                                    <div className="col-md-4 mb-4" key={'w' + i}>
                                                        <EmbeddedCard
                                                            tokenId={cards[i]}
                                                            cardType={getCardType(i)}
                                                            stats={stats}
                                                        />
                                                    </div>
                                                );
                                            })}

                                            {cards.length === 0 &&
                                                <div className="col-md-6 mx-auto mt-5 text-center">
                                                    <h5>Your wallet is empty.</h5>
                                                </div>
                                            }
                                        </div>

                                        <div className={`row ${selectedFilter != 1 && "d-none"}`}>
                                            {cards2.map((key, i) => {
                                                return (
                                                    <div className="col-md-4 mb-4" key={'w' + i}>
                                                        <EmbeddedCard2 metadata={cards2[i]} />
                                                    </div>
                                                );
                                            })}

                                            {cards2.length === 0 &&
                                                <div className="col-md-6 mx-auto mt-5 text-center">
                                                    <h5>Your wallet is empty.</h5>
                                                </div>
                                            }
                                        </div>

                                    </>
                                }
                            </>
                            :

                            <div className="row">
                                <div className="col-md-4 mx-auto mt-5 text-center">
                                    <h5>In order to see your tokens, you need to connect your wallet</h5>

                                    <button className="btn btn-peach btn-outline round mx-auto mt-5 px-3" onClick={() => { connect() }}>CONNECT</button>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default WalletPage;