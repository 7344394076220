import config from "../../../config";

const SecondDrops = () => {
  return (
    <div className="row mt-5 d-flex flex-wrap justify-content-between">
      <div className="col-md-6 col-lg-3">
        <div className="nft-card emerald">
          <h5 className="nft-type-label">REBOUNDS</h5>
          <h4 className="nft-name-label">NEBULA EMERALD</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS_2[3]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>59</h3>
              <h5>0.22 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Rebounds"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card pink">
          <h5 className="nft-type-label">BLOCKS</h5>
          <h4 className="nft-name-label">NEBULA PINK</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS_2[2]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>59</h3>
              <h5>0.22 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Blocks"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card orange">
          <h5 className="nft-type-label">2022 RISING STAR</h5>
          <h4 className="nft-name-label">STAR ORANGE</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS_2[1]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>35</h3>
              <h5>0.22 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=2022%20Rising%20Star"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-3">
        <div className="nft-card crystal">
          <h5 className="nft-type-label">2022 ALL-STAR DEBUT</h5>
          <h4 className="nft-name-label">STAR CRYSTAL</h4>

          <div
            className="d-block"
            dangerouslySetInnerHTML={{
              __html: `
        <video
            loop
            muted
            autoplay
            playsinline
            src="${config.NFT_ANIMS_2[0]}"
        />`,
            }}
          ></div>

          <div className="d-flex justify-content-between">
            <div className="nft-stats">
              <h4>MINTED</h4>
              <h3>22</h3>
              <h5>0.22 ETH</h5>
            </div>

            <a
              className="btn btn-outline"
              href="https://opensea.io/collection/lamelo-ball-collectibles?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=2022%20All%20Star%20Debut"
              target="_blank"
              rel="noreferrer"
            >
              BUY
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondDrops;
