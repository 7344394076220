import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './Redeem.css';
import Web3Ctx from '../Context/Web3Ctx';
import { getContract } from '../Utils/GetContract';
import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';
import { BigNumber } from '@ethersproject/bignumber';
import { ethers } from "ethers";
import md5 from "blueimp-md5";

import bgImage from '../../assets/images/drop_bg.jpg';

import config from '../../config';
import EmbeddedCard from '../common/EmbeddedCard';
import ModalPopup from '../common/ModalPopup';
import axios from 'axios';
import { getTokenMeta } from '../Utils';

const FULL_NAME = "full_name";
const EMAIL = "email";
const ADDRESS = "address";
const CITY = "city";
const ZIP_CODE = "zip_code";
const COUNTRY = "country";

const initialState = {
    // KYC Form
    [FULL_NAME]: "",
    [EMAIL]: "",
    [ADDRESS]: "",
    [CITY]: "",
    [ZIP_CODE]: "",
    [COUNTRY]: ""
};

const placeholders = {
    [FULL_NAME]: "Full name",
    [EMAIL]: "Email",
    [ADDRESS]: "Address",
    [CITY]: "City",
    [ZIP_CODE]: "Zip code",
    [COUNTRY]: "Country"
};

const initialErrorState = {
    // KYC Form Error
    [FULL_NAME]: null,
    [EMAIL]: null,
    [ADDRESS]: null,
    [CITY]: null,
    [ZIP_CODE]: null,
    [COUNTRY]: null
};

const Redeem = (props) => {



    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);
    const [laMeloContract, setLaMeloContract] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState(initialState);
    const [formError, setFormError] = useState(initialErrorState);

    const [showConfirm, setShowConfirm] = useState(false);

    const [tokenId, setTokenid] = useState(null);
    const [traitId, setTraitId] = useState(null);
    const [traitRedeemable, setTraitRedeemable] = useState(null);
    const [ownedAndUnclaimed, setOwnedAndUnclaimed] = useState(null);

    const [currentTrait, setCurrentTrait] = useState(null);

    const [successfulRedeem, setSuccessfulRedeem] = useState(false);



    useEffect(() => {
        console.log('REDEEM');
        setSuccessfulRedeem(false);
        const initContract = async () => {
            // console.log('WALLET [ethersProvider] running', ethersProvider)
            const contract = await getContract('LaMelo', ethersProvider).catch(e => console.log('err:', e));
            setLaMeloContract(contract);
        }

        if (ethersProvider) {
            initContract();
        }
    }, [ethersProvider]);

    useEffect(() => {


        const getTokenData = async () => {

            setIsConnected(true);
            const canRedeeem = await checkOwnerAndTrait();

            setOwnedAndUnclaimed(canRedeeem);
        }





        if (laMeloContract && address) {
            getTokenData();

        } else {
            setIsConnected(false);
        }
    }, [laMeloContract, address]);




    const checkOwnerAndTrait = async () => {

        let tId = null;
        let trId = null;
        if (props.match && props.match.params) {
            //console.log(props.match.params)
            tId = props.match.params.tokenId;
            trId = props.match.params.traitId;

            if (tId && trId) {
                setTokenid(tId);
                setTraitId(trId);
            } else {
                return false;
            }
        } else {
            return false;
        }

        setIsLoading(true);

        let ownerAddress = await laMeloContract.ownerOf(tId);

        const owned = ownerAddress.toLowerCase() === address.toLowerCase();

        const url = config.META_SERVER + '/' + tId % 100 + '/' + tId + '.json'

        let redeemed = true;
        const m = await getTokenMeta(url);
        if (m.traits && m.traits.length > 0) {
            //console.log(m.traits.find(trait=> trait.id == trId));
            const t = m.traits.find(trait => trait.id == trId);
            setCurrentTrait(t);


            console.log(t);
            if (t && t.properties.max_usages > 0) {
                redeemed = false;
            }
        }
        setIsLoading(false);
        return owned && !redeemed;
    }



    const connect = async () => {
        if (onboard) {
            await onboard.walletSelect();
        }
    }

    const updateFormData = (key, value) => {
        console.log(key, value);
        setFormData({ ...formData, [key]: value });
    }

    const submitForm = () => {
        if (!validateForm()) {
            console.log('invalid form');
            return;
        }
        setFormError(initialErrorState);
        setShowConfirm(true);
    }


    const validateFormField = (key) => {

        if (formData[key] === null || formData[key] === "") {
            setFormError({ ...formError, [key]: true });
        } else {
            setFormError({ ...formError, [key]: false });
        }
    }

    const clearErrorState = (key) => {
        setFormError({ ...formError, [key]: false });
    }

    const validateForm = () => {
        let valid = true;
        let newError = { ...formError };
        Object.keys(formData).forEach((k) => {
            if (formData[k] === null || formData[k] === "") {
                valid = false;
                newError[k] = true;
            }
        });
        setFormError(newError);
        return valid;
    }

    const redeemTrait = async (params) => {
        return axios({
            method: "post",
            url: `${config.META_SERVER}/redeem`,
            data: params,
        })
            .then((res) => {
                toast.success("Successfully redeemed trait");

               setSuccessfulRedeem(true);


                /* props.history.push({
                    pathname: '/wallet'
                }); */
            })
            .catch(handleApiError);
    };

    const submitKycData = async (params) => {
        return axios({
            method: "post",
            url: `${config.META_SERVER}/kyc`,
            data: params,
        })
            .then((res) => {
                return res.data;
            })
            .catch(handleApiError);
    };

    const handleApiError = (e) => {
        console.error(e);
        if (e.response && e.response.data && e.response.data.message) {
            toast.error(e.response.data.message);
        } else {
            toast.error(e.message)
        }
    };

    const handleWalletError = (e) => {
        if (e.error && e.error.message) {
            console.error(e.error.message);
            toast.error(e.error.message);
        } else if (e.message) {
            console.error(e.message);
            toast.error(e.message);
        }
    };

    const sendKyc = async () => {
        console.log("submitting form");
        if (isLoading) {
            return;
        }
        setShowConfirm(false);
        setIsLoading(true);

        let params = { ...formData };
        params.token_id = tokenId;
        params.trait_id = traitId;
        params.owner_address = address;

        const response = await submitKycData(params);

        if (response) {
            const rowId = response.row;
            const timestamp = response.timestamp;
            const SECONDS_IN_DAY = 86400;
            // Add one day time limit for block to be mined
            const paddedTimestamp = Number(timestamp) + Number(SECONDS_IN_DAY);

            console.log(rowId);

            const kycHash = hashKycData({ ...formData });
            const messageHash = hashMessage(kycHash, rowId, paddedTimestamp);
            const signature = await personalSign(messageHash, address);

            if (!signature) {
                setIsLoading(false);
                return;
            }
            //Recover the address from signature
            const recoveredAddress = ethers.utils.verifyMessage(
                ethers.utils.arrayify(messageHash),
                signature
            );
            if (address.toLowerCase() == recoveredAddress.toLowerCase()) {
                console.log("Signature is validated");
            } else {
                toast.error("Failed to validate signature");
                setIsLoading(false);
                return;
            }

            await redeemTrait({
                tokenId: tokenId,
                kycHash: kycHash,
                traitId: traitId,
                rowId: rowId,
                timestamp: paddedTimestamp,
                signature: signature,
            });
        }

        setIsLoading(false);
    }

    const personalSign = async (message, address) => {
        return ethersProvider
            .send("personal_sign", [message, address.toLowerCase()])
            .catch(handleWalletError);
    };

    const hashMessage = (kycHash, rowId, timestamp) => {
        let hexTimestamp = timestamp.toString(16).padStart(16, "0");
        let padRowId = rowId.toString(16).padStart(16, "0");
        const message = `${kycHash}${padRowId}${hexTimestamp}`;
        console.info("messageHash, ", message);
        return ethers.utils.keccak256(message.toString());
    };

    const hashKycData = (params) => {
        let message = JSON.stringify(params);
        return "0x" + md5(message);
    };

    return (
        <div id="application" style={{ backgroundImage: `url(${bgImage})`, backgroundColor: '#1C2330' }}>
            <Navigation {...props} />
            <div className="container">
                <div className="row padd">
                    <div className="col-lg-12 mt-5">
                    {successfulRedeem?    
                        <>
                            <div className="text-center">
                                <h2 className="text-uppercase">Successfully redeemed trait</h2>
                                <p className="mt-5 mb-1 text-white">You have successfully claimed your physical redeemable.</p>
                                <p className="text-white">Please join our <a className="discord-link" href="https://discord.gg/lameloball" target="_blank" rel="noreferrer">discord</a> for any further info regarding the delivery status.</p>
                                <button className="mt-5 btn btn-outline round mx-auto" onClick={(e)=>{props.history.push({pathname: '/wallet'});}}>BACK TO WALLET</button>
                            </div>
                        </>
                        :
                        <>
                        <h2 className=" text-center text-uppercase">{currentTrait && currentTrait.name}</h2>
                        {currentTrait && <div className="row">
                            <div className="col-md-8 mx-auto text-center mt-4 mb-3 lead-text">
                                <div className='col-4 mx-auto'>
                                    <img className="w-50" src={`${process.env.PUBLIC_URL}/traits/${currentTrait.icon}`} alt={currentTrait.name} />
                                </div>
                                <p className="mt-4 mb-0">In order to get the physical redeemable you will have to submit the form and sign a transaction.</p>
                                <p  className="">You can only use this trait once and after submission the trait will be removed from the NFT.</p>
                            </div>
                        </div>}


                        {isConnected ?

                            <div className="row">

                                {isLoading ?
                                    <div className="col-lg-12 mt-5 mb-5 text-center">
                                        <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                    </div>

                                    :
                                    <>
                                        {ownedAndUnclaimed ?
                                            <div className="col-lg-8 mt-3 mb-5 mx-auto">

                                                <div className="form-bg p-4 text-center">

                                                    {Object.keys(formData).map((k, i) => {
                                                        return (
                                                            <div key={'form' + k} className="form-group">
                                                                <input type="text" className="form-control"
                                                                    placeholder={placeholders[k]}
                                                                    onChange={(e) => updateFormData(k, e.target.value)}
                                                                    onBlur={(e) => { validateFormField(k) }}
                                                                    onClick={(e) => { clearErrorState(k) }}
                                                                />
                                                                {formError[k] === true && <div className="text-danger field-error">Required field</div>}
                                                            </div>

                                                        )
                                                    })}

                                                    <button className="btn btn-outline round" onClick={submitForm}>SUBMIT</button>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-lg-8 mt-3 mb-5 mx-auto">
                                                <h4 className="text-center">Already redeemed</h4>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            :

                            <div className="row">
                                <div className="col-md-4 mx-auto mt-5 text-center">
                                    <h5>In order to redeem, you need to connect your wallet</h5>

                                    <button className="btn btn-peach btn-outline round mx-auto mt-5 px-3" onClick={() => { connect() }}>CONNECT</button>
                                </div>
                            </div>
                        }
                        </>
}


                    </div>

                </div>
            </div>

            <ModalPopup showModal={showConfirm} onClose={() => { console.log('close....'); setShowConfirm(false) }} onSubmit={sendKyc}>
                <h5 className="text-center">Confirmation</h5>
                <p>After confirmation is given the redeem trait will be consumed.</p>
            </ModalPopup>



        </div>


    );
}

export default Redeem;