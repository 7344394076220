import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/FooterComponent";
import bgImage from "../../assets/images/new-bg-2.jpg";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div
      id="application"
      style={{ backgroundImage: `url(${bgImage})`, backgroundColor: "#1C2330" }}
    >
      <Navigation />
      <div id="tnc" className="container mt-5" style={{ color: "#fff" }}>
        <p dir="ltr">PLAYGROUND STUDIOS PRIVACY POLICY</p>
        <br />
        <p dir="ltr">Last Revised on November, 22 2021</p>
        <br />
        <p dir="ltr">
          Welcome to the Privacy Policy for www.lameloball.io (the “Website”),
          operated by Playgrounds Labs LLC (“Company”, “we” or “us”). This
          Privacy Policy describes how we collect, use and disclose information
          from our users, including with respect to the Website and the
          Company’s content, applications, services, tools and features
          (collectively, the “Services”).
        </p>
        <br />
        <p dir="ltr">
          For purposes of this Privacy Policy, “you” and “your” means you as the
          user of the Services. If you use the Services on behalf of a company
          or other entity then "you" includes you and that entity, and you
          represent and warrant that (a) you are an authorized representative of
          the entity with the authority to bind the entity to these Terms, and
          (b) you agree to these Terms on the entity's behalf.
        </p>
        <br />
        <p dir="ltr">
          The Services can only be accessed and used subject to the Company’s
          Terms of Service and this Privacy Policy. PLEASE READ THIS PRIVACY
          POLICY CAREFULLY. BY USING, ACCESSING, OR DOWNLOADING ANY OF THE
          SERVICES, YOU AGREE THAT YOU ARE BOUND BY THE COMPANY’S TERMS OF
          SERVICE AND PRIVACY POLICY. IF YOU DO NOT AGREE TO OUR TERMS OF
          SERVICE AND PRIVACY POLICY, THEN YOU MAY NOT ACCESS AND USE THE
          SERVICES.
        </p>
        <ol>
          <li dir="ltr">
            <h1 dir="ltr">TYPES OF INFORMATION WE COLLECT</h1>
            <p dir="ltr">
              We may collect the following categories of information from you
              (“Information”), which includes:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  “Personal Information” means Information that identifies
                  (whether directly or indirectly) a particular individual, such
                  as the individual’s name, postal address, email address and
                  telephone number. Personal Information includes registration
                  information you provide when you create an account, such as
                  your name, email address, telephone number, birthdate,
                  username and password.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  “Non-Personal Information” means Information that does not
                  directly or indirectly identify, and cannot reasonably be used
                  to identify, an individual user. When Non-Personal Information
                  is directly or indirectly associated with Personal
                  Information, this Non-Personal Information is treated as
                  Personal Information.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Information you provide to us when you access or use the
                  Services and each time you interact with the Services or, for
                  example, when you update information in your control panel,
                  communicate with us by telephone or email, and order or new
                  products. We also store information about the products you
                  purchase through the Services, such as the product
                  information, date of purchase, and information relating to any
                  support issue.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Information you provide in public forums through the Services,
                  including using our review, comment, post or similar
                  functionality.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Usage, viewing and technical data, including your IP address
                  or device identifier when you use the Services. If you access
                  your account from a mobile device, that mobile device may also
                  provide us with details of your location. Most mobile devices
                  allow you to disable this functionality.
                </p>
              </li>
            </ul>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">HOW WE COLLECT YOUR INFORMATION</h1>

            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  We collect Information you provide to us when you register
                  with us; create user generated content; send us your shipping
                  information in connection with your purchase of a physical
                  item on the Website, or otherwise interact with us.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We collect Information through unique identifiers (such as IP
                  address or your mobile device advertising identifier).
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We use “cookies” and other technologies to collect data that
                  enables us to better understand and improve the usability,
                  performance and effectiveness of our Website. Cookies are
                  files sent to your browser and stored on your computer. We
                  uses cookies to gather general information about your visits
                  to our website, the files you have designated for backup, the
                  configuration of your computer and your computer’s interaction
                  with the Services, and stores this information in log files.
                  We use this information to understand traffic and downloads on
                  our website, enabling us to improve the website, provide the
                  best online experience possible, and improve our customer
                  service. We use the following types of cookies on our site:
                </p>
              </li>
              <ul>
                <li dir="ltr">
                  <p dir="ltr">
                    Strictly necessary cookies - These cookies are essential in
                    order to enable you to move around our website and use its
                    features, such as accessing secure areas of the website and
                    remembering which files you have marked for backup. Without
                    these cookies, services you have asked for, like security or
                    e-billing, cannot be provided.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Performance cookies - These cookies collect information
                    about how you use the website. These cookies don’t collect
                    information that identifies you and are only used to improve
                    how the website works.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Functionality cookies - These cookies allow the website to
                    remember choices you make, such as the configuration of your
                    computer, to provide enhanced, more personal features.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    Other Technologies - We may also use other Internet
                    technologies, such as web beacons or pixel tags and other
                    similar technologies, to deliver or communicate with cookies
                    and analyze your use of the Services.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    We also may include web beacons in e-mail messages so that
                    we know when you have opened an email message received from
                    us.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    You may disable cookies by changing the settings on your
                    browser. Check the help menu in your browser for further
                    information. Remember that if you disable cookies it is
                    likely to affect how the Services work and you may not be
                    able to access or use certain areas of our website or
                    functionality. Unless you have adjusted your browser setting
                    so that it will refuse cookies, our system will place
                    cookies when you log on to the Services.
                  </p>
                </li>
              </ul>
              <li dir="ltr">
                <p dir="ltr">
                  [We use third parties’ analytic and tracking tools to better
                  understand who is using the Service, how people are using the
                  Service and how to improve the effectiveness of the Service
                  and its content. Those third party companies may use cookies,
                  pixel tags or other technologies to collect and store
                  Non-Personal Information such as time of visit, pages visited,
                  time spent on each page of the Service, device identifiers,
                  type of operating system used and other website(s) you may
                  have visited. They might combine information they collect from
                  your interaction with Service with Personal Information they
                  collect from other sources. We do not have access to, nor
                  control over, third parties' use of cookies or other tracking
                  technologies.]
                </p>
              </li>
            </ul>

            <p dir="ltr">Online Tracking</p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Do Not Track (DNT) is a privacy preference that users can set
                  in some web browsers, allowing users to opt out of tracking by
                  websites and online services. The Company and the Services do
                  not recognize DNT.
                </p>
              </li>
            </ul>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">HOW WE USE YOUR INFORMATION</h1>
            <p dir="ltr">
              We may use your data for the following purposes:
              <br />
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  To provide you with the Services and the products and services
                  you request.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">To administer your Company account.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To communicate with you about your account or transactions
                  with us and send you information about features of the
                  Services or changes to our policies.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To ensure consistency with local law and choices and controls
                  that may be available to you.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To detect, investigate and prevent activities that may violate
                  our policies or be illegal.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To optimize or improve the content, products, services, and
                  features of the Services.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To personalize and improve the Services and user experiences,
                  to increase the functionality and user friendliness of the
                  Services, to deliver content or features that match user
                  profiles or interests.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To monitor and analyze the Services usage and trends and
                  otherwise measure the effectiveness of the services.
                </p>
              </li>

              <li dir="ltr">
                <p dir="ltr">
                  We may also send you offers and promotions for our products
                  and services provided you have consented to receive these.
                </p>
              </li>
            </ul>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">SHARING YOUR INFORMATION WITH OTHER COMPANIES</h1>

            <p dir="ltr">
              We will not share your Personal Information outside of the Company
              except in limited circumstances, including:
            </p>
            <br />

            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  When you allow us to share your Personal Information with
                  another company, such as directing us to share your Personal
                  Information with third-party sites or platforms, such as
                  social networking sites.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  When we contract or partner with a third party to provide
                  services on our behalf, including Services development and
                  maintenance. We require all third parties to process your data
                  in accordance with this Privacy Policy and as permitted by
                  applicable data protection laws.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  When we share Personal Information with third parties in
                  connection with the sale of a business (including merger,
                  acquisition, or sale of all or a material portion of its
                  assets, change in corporate control, or insolvency or
                  bankruptcy proceedings).
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  To enforce our Terms of Service or rules, to ensure the safety
                  and security of our users and third parties, to protect our
                  rights and property and the rights and property of our users
                  and third parties, to comply with legal process, including
                  judicial warrant, rule, order or subpoena or in other cases if
                  we believe in good faith that disclosure is required by law or
                  regulation.
                </p>
              </li>
            </ul>
            <p dir="ltr">
              In addition, we may share Non-Personal Information with third
              parties, alone or in combination with your Non-Personal
              Information or the Non-Personal Information of other users.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">
              PUBLIC VENUES AND SEMI-PUBLIC VENUES OF THE SERVICES.
            </h1>

            <p dir="ltr">
              If you use a message board, comment function, conversation pages,
              blogs, social community environments, profile pages, or other
              public or semi-public venue or forums of the Services, you will be
              identified on those venues by the account name you submit.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">YOUR CONTROLS AND CHOICES</h1>

            <p dir="ltr">
              We provide you the ability to exercise certain controls and
              choices regarding our collection, use and sharing of your
              Information. Your controls and choices may include:
            </p>

            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  You may correct, update and delete your account.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  You may change your choices for newsletters and alerts.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  You may choose whether to receive offers and promotions for
                  our products and services.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  You may request access to the Personal Information we hold
                  about you and that we amend or delete it.
                </p>
              </li>
            </ul>

            <p dir="ltr">
              You may exercise your controls and choices, or request access to
              your Personal Information, by modifying your profile or by
              contacting us at{" "}
              <a href="mailto:team@playgroundstudios.io">
                team@playgroundstudios.io
              </a>{" "}
              or following instructions provided in communications sent to you.
              Please be aware that, if you do not allow us to collect Personal
              Information from you, we may not be able to deliver certain
              products and services to you, and some of our services may not be
              able to take account of your interests and preferences. If you
              have questions regarding the specific Personal Information about
              you that we process or retain, please contact us at{" "}
              <a href="mailto:team@playgroundstudios.io">
                team@playgroundstudios.io
              </a>
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">THIRD PARTY WEBSITES AND LINKS</h1>

            <p dir="ltr">
              Our Services may contain links to other sites operated by third
              parties. The Company does not control such other sites and is not
              responsible for their content, their privacy policies, or their
              use of Personal Information. Information you provide on public or
              semi-public venues, including Information you share on third party
              social networking platforms (such as Discord or Twitter) may also
              be viewable by other users of the Services and users of those
              third party platforms without limitation as to its use by us or by
              a third party. The Company’s inclusion of such links does not, by
              itself, imply any endorsement of the content on such sites or of
              their owners or operators except as disclosed on the Services. The
              Company expressly disclaims any and all liability for the actions
              of third parties, including but without limitation to actions
              relating to the use and/or disclosure of Personal Information by
              third parties. Any information submitted by you directly to these
              third parties is subject to that third party's privacy policy.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">DATA SECURITY, INTEGRITY AND RETENTION</h1>
            <p dir="ltr">
              We do not seek or knowingly collect any Personal Information about
              persons under 18 years of age. If we become aware that we have
              unknowingly collected Personal Information from a person under the
              age of 18, we will make commercially reasonable efforts to delete
              such information from our database.
            </p>
            <p dir="ltr">
              If you are the parent or guardian of a minor child who has
              provided us with Personal Information, you may contact us to
              request it be deleted.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">DATA SECURITY, INTEGRITY AND RETENTION</h1>

            <p dir="ltr">
              9.1 General. We use reasonable security measures, including
              computer safeguards and secured files and facilities that comply
              with federal law] to protect and limit access to Personal
              Information. Please note that Information you send to us
              electronically may not be secure when it is transmitted to us. We
              recommend that you do not use unsecure channels to communicate
              sensitive or confidential information to us. From time to time, we
              review our security procedures to consider appropriate new
              technology and methods. Please be aware though that, despite our
              best efforts, no security measures are perfect or impenetrable. We
              will retain your Personal Information for the length of time
              needed to fulfill the purposes outlined in this privacy policy
              unless a longer retention period is required, or for the
              establishment, exercise or defense of legal claims, or for
              legitimate businesses purposes, or as provided by law.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">UPDATING THIS PRIVACY POLICY</h1>

            <p dir="ltr">
              We may modify this Privacy Policy from time to time in which case
              we will update the “Last Revised” date at the top of this Privacy
              Policy. If we make changes that are material, we will use
              reasonable efforts to attempt to provide notice to you and, where
              required by applicable law, we will obtain your consent. Notice
              may be by email to you at the last email address you provided us,
              by posting notice of such changes on the Services, or by other
              means, consistent with applicable law. However, it is your sole
              responsibility to review the Privacy Policy from time to time to
              view any such changes. The updated Privacy Policy will be
              effective as of the time of posting, or such later date as may be
              specified in the updated Privacy Policy. IF YOU DO NOT ACCEPT AND
              AGREE TO THIS PRIVACY POLICY, INCLUDING ANY CHANGES THERETO, THEN
              YOU MUST NOT ACCESS OR USE THE SERVICES.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">YOUR CALIFORNIA PRIVACY RIGHTS</h1>

            <p dir="ltr">
              This Privacy Policy describes how we may share your Information
              for marketing purposes. It applies to all users of the Services,
              including California residents.
            </p>
            <p dir="ltr">
              [As a California resident, you may contact us with any questions
              or to request a list of third parties to whom we may disclose
              Information for such third parties’ marketing purposes and the
              categories of information we may disclose. See How to Contact Us
              below.
            </p>
          </li>

          <li dir="ltr">
            <h1 dir="ltr">HOW TO CONTACT US</h1>

            <p dir="ltr">
              You may contact us regarding the Services or this Privacy Policy
              at, including with any questions or concerns regarding the use or
              disclosure of Personal Information, at:{" "}
              <a href="mailto:team@playgroundstudios.io">
                team@playgroundstudios.io
              </a>
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
