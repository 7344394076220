/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './Navigation.css';
import logo from '../../assets/images/logo-in-menu.png';
import Address from '../common/Address';
import Web3Ctx from '../Context/Web3Ctx';

const Navigation = (props)=>{
    const [toggleNav, setToggleNav] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const {onboard, address, connecting} = useContext(Web3Ctx);
    const [opaqueBg, setOpaqueBg] = useState(false);

    useEffect(()=>{
        //console.log('event listener added',address);
        window.addEventListener('scroll', handleOnScroll);

        return () => {
           // console.log('event listener removed');
            window.removeEventListener('scroll', handleOnScroll);
        }
    },[]);

/* 
    useEffect(()=>{
        if(state.)
    },[state]);
 */

    const handleConnect = async () => {
        if (onboard) {
            await onboard.walletSelect();
        }
    }

    const handleDisconnect = () => {
        // console.log('disconnect clicked');
        if (onboard) {
            onboard.walletReset();
            if (window && window.localStorage) {
                localStorage.removeItem('selectedWallet');
            }
        }
    }

    const   handleToggleNav = () => {
        setToggleNav(!toggleNav);
    }
    const   handleOnScroll = () => {
       // console.log(window.scrollY);
        if(window.scrollY>70){
                setOpaqueBg(true);
        }else{
                setOpaqueBg(false);
        }
    }

    const toggleDropdown = (e) => {
        e.preventDefault();
        setDropdownVisible(!dropdownVisible);
      //  setDropdownDocsVisible(false);
    }
/* 
    const toggleDropdownDocs = (e) => {
        e.preventDefault();
        setDropdownVisible(false);
        setDropdownDocsVisible(!dropdownDocsVisible);
    } */
    const auctionHandler= ()=>{
        if(props.auctionHandler){
            props.auctionHandler();
        }else{
           // console.log(props);
            props.history.push({
                pathname: '/', 
                state: { auction: true }
            });
        }
    }

    return (
        <nav className={`navbar navbar-expand-lg fixed-top navbar-dark tbx-nav ${opaqueBg?'opaque-bg':''} ${props.className}`}>
       
       

        <div className="container">
                <Link className="navbar-brand nav-link" to="/"><img src={logo} alt="toolbox"/></Link>
                <button className="navbar-toggler" type="button" onClick={handleToggleNav}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse ${toggleNav?'show':''} navbar-collapse text-right`} id="navbarText">
                    <ul className="navbar-nav ml-auto mr-3">
                        <li className="nav-item dw">
                            <NavLink className="nav-link" to="/" exact>HOME</NavLink>
                        </li>

                        <li className="nav-item dw">
                            <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                       </li>

                        <li className="nav-item dw">
                            <NavLink className="nav-link" to="/wallet">WALLET</NavLink>
                       </li>

                        <li className="nav-item dw">
                        <NavLink className="nav-link" to="/the-drop">DROP ROOM</NavLink>
                       </li>

                        {!connecting && <>
                            {address ?
                                    <li className="nav-item ml-4">
                                        <Address address={address} short blockie scale={3}/>
                                        <p className="disconnect" onClick={handleDisconnect}>disconnect</p>
                                    </li>
                            :
                                    <li className="nav-item ml-4">
                                        <button className="btn btn-outline" onClick={handleConnect}>connect to wallet</button>
                                    </li>
                            }
                    </>}

                    </ul>
                    
                </div>
        </div>
            </nav>
    );

}
export default Navigation;
