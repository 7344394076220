import React, { useContext, useEffect, useState,useRef } from 'react';

import './Card.css';
import useContainerDimensions from '../common/useContainerDimensions';
import config from '../../config'
import EmbeddedCard from '../common/EmbeddedCard';

const Card = (props) => {

    const containerRef = useRef()
    const { width, height } = useContainerDimensions(containerRef);
    const [tokenId,setTokenId] = useState(null);



    useEffect(()=>{

    },[width,height]);

    useEffect(()=>{
       
        let id = props.match.params.tokenId?parseInt(props.match.params.tokenId):0;

        if(id>9999){
           // setIllegalId(true);
            return;
        }else{
          //  setIllegalId(false);
          setTokenId(id);
        }


    },[props]);


    return(
        <div ref={containerRef} className="e-container OS">
            <div className="positioner mx-auto p-4" style={{width: width<height/1.365?100+'%':height/1.365,
                                                            marginTop: width<height/1.365?(height-(width*1.365))/2:0}}>
                {tokenId!=null && <EmbeddedCard demoMode="true"
                    tokenId={tokenId}
                    cardType={tokenId<=500?0:tokenId<=1500?1:tokenId<=3500?2:3}
                />}
            </div>

        </div>
    );
}

export default Card;